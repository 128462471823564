import { Component } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { getActiveGym, getGyms, sickla, gasverket } from 'src/helpers/gymHelper';
import { deflateRaw } from 'zlib';
import { contentful } from '../services/contentful'
import { defaultOptions } from 'src/contentful/helper';
const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');

type stateType = { leftHeader: string, leftMiddle: string, leftBottom: string, rightHeader: string, rightMiddle: string, rightBottom: string, findUsTitle: string, findUsUrl: string }

class Footer extends Component {
	state: stateType = { leftHeader: "", leftMiddle: "", leftBottom: "", rightHeader: "", rightMiddle: "", rightBottom: "", findUsTitle: "", findUsUrl: "/" };

	constructor(props: any) {
		super(props);
	}
    componentDidMount() {
		const activeGym = getActiveGym()
		contentful.getContactPage(activeGym.gym).then(response => {
			const leftMiddle = response.items[0].fields.leftMiddle && documentToHtmlString(response.items[0].fields.leftMiddle, defaultOptions);
			const leftBottom = response.items[0].fields.leftBottom && documentToHtmlString(response.items[0].fields.leftBottom, defaultOptions);

			const rightMiddle = response.items[0].fields.rightMiddle && documentToHtmlString(response.items[0].fields.rightMiddle, defaultOptions);
			const rightBottom = response.items[0].fields.rightBottom && documentToHtmlString(response.items[0].fields.rightBottom, defaultOptions);

			this.setState({
				leftHeader: response.items[0].fields.leftHeader,
				leftMiddle,
				leftBottom,

				rightHeader: response.items[0].fields.rightHeader,
				rightMiddle,
				rightBottom,
				findUsUrl: response.items[0].fields.findUsUrl,
				findUsTitle: response.items[0].fields.findUsTitle,
			})
		})
	}
	render() {
		return (
			<div>
				<div className="footer">
					<div className="footercontainer">
						<div className="div-block-3">
							<div>
								<h4 className="h4 bold white">{this.state.leftHeader}</h4>
							</div>
							<div className="div-block-2 bottom0">
								<div dangerouslySetInnerHTML={{ __html: this.state.leftMiddle }} />
							</div>
							<div dangerouslySetInnerHTML={{ __html: this.state.leftBottom }} />
						</div>
						<div className="div-block-3">
							<div>
								<h4 className="h4 bold white">{this.state.rightHeader}</h4>
							</div>
							<div className="div-block-2 bottom0">
								<div dangerouslySetInnerHTML={{ __html: this.state.rightMiddle }} />
							</div>
							{
								this.state.rightBottom && <div className="div-block-2">
									<div dangerouslySetInnerHTML={{ __html: this.state.rightBottom }} />
								</div>
							}
							<div className="div-block-38">
								<a href="https://www.instagram.com/klatterverket/?hl=sv" target="_blank" className="socialmediaicon insta w-inline-block"><div></div></a>
								<a href="https://www.facebook.com/klatterverket/" target="_blank" className="socialmediaicon insta w-inline-block"><div></div></a>
							</div>
							<Link to={`${this.state.findUsUrl}`} className="div-block-5 w-inline-block">
								<div className="arrowicon white">
									<div className="arrowicon white">→</div>
								</div>
								<div>
									<div>
										<h4 className="h5">{this.state.findUsTitle}</h4>
									</div>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Footer;
