import { Component } from 'react';
import * as React from 'react';
import { ImageFields } from 'src/types';
import { defaultOptions } from 'src/contentful/helper';
import { HeaderWithTitle } from './HeaderWithTitle';
const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');
// import { LayoutItem } from 'src/types';

class VerticalImagesAndTextTemplate extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = { html: '' };
	}
	componentDidMount() {
		const title = this.props.fields.title;
		const slug = this.props.fields.slug;
		const layout = this.props.fields.layout;
		const leftContent = layout.fields.leftContent;
		const rightContent = layout.fields.rightContent;
		const textLeft = layout.fields.textLeft;

		console.log(leftContent);
		let leftHtml = leftContent;
		let rightHtml = rightContent;
		if (leftHtml && leftHtml.content) {
			leftHtml = documentToHtmlString(leftContent, defaultOptions);
		}
		if (rightHtml && rightHtml.content) {
			rightHtml = documentToHtmlString(rightContent, defaultOptions);
		}

		this.setState({ title, slug, html: 'result', textLeft, leftHtml, rightHtml });
	}

	render() {
		const leftHtml = <div dangerouslySetInnerHTML={{ __html: this.state.leftHtml }} />;
		const rightHtml = <div dangerouslySetInnerHTML={{ __html: this.state.rightHtml }} />;
		console.log(this.props);
		return (
			<React.Fragment>
				<div className="contentwrapper">
					<div className="div-block-37" id={'single-news-container' + this.props.index}>
						<div>
							<HeaderWithTitle {...this.props} />
						</div>
					</div>
					<div className="div-block-57">
						<div className={this.state.textLeft ? 'leftcolumn big' : 'leftcolumn'}>{leftHtml}</div>
						<div className={!this.state.textLeft ? 'rightcolumn' : 'rightcolumn small'}>{rightHtml}</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default VerticalImagesAndTextTemplate;
