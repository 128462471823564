import * as React from 'react';
import { withFormik, FormikProps, Field } from 'formik';
import * as Yup from 'yup';
import { PricePageState, GroupedOptionList } from './PricePageDynamic';

interface IFormValues {
  firstName: string;
  lastName: string;
  personalNumber?: number;
  address: string;
  postalCode?: number;
  city: string;
  phone: string;
  email: string;
  acceptedTerms: boolean;
}

interface IOtherProps {
  title?: string;
}

interface IMyFormProps {
  initialFirstName?: string;
  initialLastName?: string;
  initialPersonalNumber?: number;
  initialAddress?: string;
  initialPostalCode?: number;
  initialCity?: string;
  initialPhone?: string;
  initialEmail?: string;
  initialAcceptedTerms?: boolean;
}

const PricePageForm = (
  props: IOtherProps &
    FormikProps<IFormValues & PricePageState> &
    PricePageState
) => {
  console.log(props.price);
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    status,
    title,
    validateOnChange = false,
    validateOnBlur = false,
    validateField
  } = props;

  let varningText = validateField ? '' : 'Fyll i fälten som är rödmarkerade';

  if (status && status.submitted) {
    return (
      <div>
        <div className="div-block-102">
          <h1 className="h2 bold">
            Vi har mottagit din ansökan
            <br />
          </h1>
        </div>
        <div className="div-block-128">
          <h1 className="h4">Välkommen att hämta ditt medlemskap på:</h1>
        </div>
        <div className="div-block-129">
          <div className="navbarheader firstpage">
            <div>
              <h1 className="hoover bold">
                Klätterverket
                <br />
              </h1>
            </div>
            <div className="div-block-61">
              <div>
                <h1 className="hoover bold">
                  /
                  {props.groupedOptionList[6].options
                    .filter(gym => gym.checked)
                    .map(gym => gym.text)}
                  <br />
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="div-block-128">
          <h1 className="h4">
            Vi har skickat en bekräftelse till den mail du angav.
          </h1>
        </div>
      </div>
    );
  } else {
    return (
      <form onSubmit={handleSubmit} style={{ marginBottom: '200px' }}>
        <div className="div-block-102">
          <h1 className="h2 bold">Dina kontaktuppgifter</h1>
        </div>
        <div className="contactwrapper">
          <div className="div-block-127">
            <div className="form-block w-form">
              <div id="email-form-2" data-name="Email Form 2" className="form">
                <div className="div-block-124">
                  <div className="div-block-125">
                    <h1 className="h3">Förnamn</h1>
                    <input
                      type="text"
                      id="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={
                        errors.firstName && touched.firstName
                          ? { borderColor: '#ED1C24' }
                          : {}
                      }
                      className="inputfield w-input"
                    />
                  </div>
                  <div className="div-block-125 right">
                    <h1 className="h3">Efternamn</h1>
                    <input
                      type="text"
                      id="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={
                        errors.lastName && touched.lastName
                          ? { borderColor: '#ED1C24' }
                          : {}
                      }
                      className="inputfield w-input"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-block-127">
            <div className="form-block w-form">
              <div id="email-form-2" data-name="Email Form 2" className="form">
                <div className="div-block-124">
                  <div className="div-block-125">
                    <h1 className="h3">Personnummer</h1>
                    <input
                      type="text"
                      id="personalNumber"
                      value={values.personalNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="inputfield w-input"
                    />
                  </div>
                  <div className="div-block-125 right" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="div-block-127"/> */}
          <div className="div-block-120">
            <div className="div-block-127">
              <div className="form-block w-form">
                <div
                  id="email-form-2"
                  data-name="Email Form 2"
                  className="form"
                >
                  <div className="div-block-124">
                    <div className="div-block-125 big">
                      <h1 className="h3">Adress</h1>
                      <input
                        type="text"
                        id="address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="inputfield w-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-block-127">
              <div className="form-block w-form">
                <div
                  id="email-form-2"
                  data-name="Email Form 2"
                  className="form"
                >
                  <div className="div-block-124">
                    <div className="div-block-125">
                      <h1 className="h3">Postnr</h1>
                      <input
                        type="text"
                        id="postalCode"
                        value={values.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="inputfield w-input"
                      />
                    </div>
                    <div className="div-block-125 right">
                      <h1 className="h3">Stad</h1>
                      <input
                        type="text"
                        id="city"
                        className="inputfield w-input"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-block-127">
              <div className="form-block w-form">
                <div
                  id="email-form-2"
                  data-name="Email Form 2"
                  className="form"
                >
                  <div className="div-block-124">
                    <div className="div-block-125">
                      <h1 className="h3">Telefonnummer</h1>
                      <input
                        type="text"
                        id="phone"
                        className="inputfield w-input"
                        onChange={handleChange}
                        style={
                          errors.phone && touched.phone
                            ? { borderColor: '#ED1C24' }
                            : {}
                        }
                        onBlur={handleBlur}
                        value={values.phone}
                      />
                    </div>
                    <div className="div-block-125 right">
                      <h1 className="h3">E-post</h1>
                      <input
                        type="text"
                        style={
                          errors.email && touched.email
                            ? { borderColor: '#ED1C24' }
                            : {}
                        }
                        id="email"
                        className="inputfield w-input"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {/* <span style={{color:"#ED1C24"}}>{errors.email}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-block-126">
            <div
              className="longbox"
              style={errors.acceptedTerms ? { borderColor: '#ED1C24' } : {}}
            >
              <div>
                {/* <Field type="checkbox" name="console" value={} /> */}

                <input
                  className="check"
                  onChange={e => {
                    values.acceptedTerms = !values.acceptedTerms;
                  }}
                  onBlur={handleBlur}
                  id="check"
                  value={values.acceptedTerms.toString()}
                  // checked={values.acceptedTerms}
                  type="checkbox"
                />
                {/* <div className="check un">
                            <div></div>
                        </div> */}
              </div>
              <div className="div-block-112">
                <div className="div-block-119">
                  <h1 className="h3">Jag godkänner</h1>
                  <a href="/sv/medlemskap" target="blank">
                    <h1 className="h3 underscore heading">medlemsvillkoren</h1>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-block-107">
          <div className="arrowicon big black" />
        </div>

        <div className="div-block-128">
          <h1 className="h4">{varningText}</h1>
        </div>
        <div className="div-block-122">
          <button
            className="button w-inline-block"
            type="submit"
            onSubmit={() => handleSubmit()}
          >
            <div>Skicka</div>
          </button>
        </div>
      </form>
    );
  }
};

type FormData = {
  text: string;
  from: string;
  to: string[];
  subject: string;
};

const getSelectedTypeText = (groupedOptionList: GroupedOptionList): string => {
  let result = '';
  groupedOptionList.options.forEach(option => {
    if (option.checked) {
      result = option.type as string;
    }
  });
  return result;
};

const Form = withFormik<
  IMyFormProps & PricePageState,
  IFormValues & PricePageState
>({
  mapPropsToValues: props => {
    console.log(props);

    let result: PricePageState & IFormValues = {
      firstName: props.initialFirstName || '',
      lastName: props.initialLastName || '',
      personalNumber: props.initialPersonalNumber,
      address: props.initialAddress || '',
      postalCode: props.initialPostalCode,
      city: props.initialCity || '',
      phone: props.initialPhone || '',
      email: props.initialEmail || '',
      groupedOptionList: props.groupedOptionList,
      price: props.price,
      acceptedTerms: props.initialAcceptedTerms || false,
      priceAdditionalText: props.priceAdditionalText
    };

    return result;
  },

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Ange en korrekt email-adress')
      .required('*'),
    firstName: Yup.string().required('*'),
    lastName: Yup.string().required('*'),
    phone: Yup.string().required('*'),
    acceptedTerms: Yup.bool().oneOf([true])
    // personalNumber: Yup.number()
    //     .required("*")
  }),
  validate({
    email,
    firstName,
    lastName,
    phone,
    city,
    postalCode,
    address,
    personalNumber,
    acceptedTerms
  }: IFormValues) {
    console.log(acceptedTerms);
    console.log(firstName);
    // debugger;
  },
  validateOnBlur: true,
  validateOnChange: true,
  handleSubmit(
    {
      email,
      firstName,
      lastName,
      phone,
      city,
      postalCode,
      address,
      personalNumber,
      acceptedTerms
    }: IFormValues,
    { props, setSubmitting, setErrors, resetForm, setStatus }
  ) {
    let choosedGym = getSelectedTypeText(props.groupedOptionList[0]);
    let ageText = getSelectedTypeText(props.groupedOptionList[1]);
    let openingHoursText = getSelectedTypeText(props.groupedOptionList[2]);
    let discountText = getSelectedTypeText(props.groupedOptionList[3]);
    let additionalEquipmentText = getSelectedTypeText(
      props.groupedOptionList[4]
    );
    let subscriptionText = getSelectedTypeText(props.groupedOptionList[5]);

    let priceText = props.groupedOptionList[5].options[2].checked
      ? '<b>Totalpris:</b> ' + props.price * 12 + ' kr'
      : '<b>Månadspris:</b> ' + props.price + ' Kr/Månad';
    let paymentText = props.groupedOptionList[5].options[2].checked
      ? 'Årskort (12 månader direkt)'
      : 'Autogiro';
    let optionGasverket = props.groupedOptionList[6].options[0];
    let optionSickla = props.groupedOptionList[6].options[1];
    let selectedGymText = optionGasverket.checked
      ? optionGasverket.text
      : optionSickla.text;

    // vuxen // hyresutrustning // utan bindningstid // autogiro // sickla
    // let textSummarize = `${discountText}, ${additionalEquipmentText}, ${subscriptionText}, ${paymentText}, ${selectedGymText}`

    let subjectToKlatterverket = `${firstName} ${lastName}, ${personalNumber ||
      'XXXXXXXX-XXXX'} har beställt medlemskap.`;

    let bodyToKlatterverket = `<b>Namn:</b> ${firstName} ${lastName}<br/>
             <b>Personnummer:</b> ${personalNumber || 'XXXXXXXX-XXXX'}<br/>
             <b>Adress:</b> ${address}, ${postalCode}, ${city}<br/>
             <b>Email:</b> <a href="mailto:${email}">${email}</a><br/>
             <b>Telefon:</b> ${phone || 'XXX-XXXXXXX'}<br/>
             <br/>
             <br/>
             <b>Hall:</b> ${choosedGym}
             <br/>           
             <b>Ålder:</b> ${ageText}
             <br/>
             <b>Kort:</b> ${openingHoursText} 
             <br/>
             <b>Rabatt:</b> ${discountText} 
             <br/>
             <b>Hyrutrustning:</b> ${additionalEquipmentText} <br/>
             <br/>
             <b>Betalsätt:</b> ${subscriptionText} <br/>
             <br/>
             ${priceText}<br/>
             </br>
             </br>
             <b>Hämtas ut på:</b> ${selectedGymText}<br/>
             </br>
         
             `;

    // mailet till klätterverket
    KlatterverketApi.sendEmail({
      text: bodyToKlatterverket,
      from: 'noreply@klatterverket.se',
      // TODO: medlemsskap
      to: ['info@klatterverket.se'],
      subject: subjectToKlatterverket
    }).then(response => {
      console.log(response);
    });

    let subjectToClient = 'Orderbekräftelse Medlemskap.';
    let bodyToClient = `Tack för att du vill bli medlem på Klätterverket.<br/>
            Ingen stress, du börjar inte betala något förrän du hämtat ut ditt kort.<br/>
            Ditt medlemskort finns redo att hämtas ut om 24 timmar. Det går bra att komma in tidigare men då behöver vi föra in dina uppgifter på plats i kassan och det kan ta några minuter extra. <br/>
            Här kommer en sammanställning av dina val:<br/>`;

    let bodyToClientLast = `<br/>
            <br/>
            </b> ${priceText}<br/>           
            <br/>
            <br/>
            Har du frågor, eller vill ändra något vänligen hör av dig till 
            <a href="mailto:info@klatterverket.se">info@klatterverket.se</a> så ordnar vi det.<br/>
            <br/>
            Välkomna in!<br/>
            Med vänliga hälsningar<br/>
            Klätterverket<br/>
            <img width="150px" src="https://uploads-ssl.webflow.com/5bb863076b929f7e796cf015/5bb9f1a7884809a9cbe079be_Kla%CC%88tterverket.jpg"/>`;

    let choosedOptions: string = '';
    props.groupedOptionList
      .filter(x => x.included)
      .forEach(item => {
        item.options.forEach(option => {
          if (option.checked) {
            if (option.showText) {
              choosedOptions += '* ' + option.text + '<br/>';
            }
          }
        });
      });

    // mailet till kunden
    KlatterverketApi.sendEmail({
      text: bodyToClient + choosedOptions + bodyToClientLast,
      from: 'noreply@klatterverket.se',
      to: [email],
      subject: subjectToClient
    }).then(response => {
      console.log(response);
    });

    setStatus({ submitted: true });
  }
})(PricePageForm);

export default Form;

const KlatterverketApi = {
  sendEmail(body: FormData): Promise<any> {
    return fetch(`${process.env.REACT_APP_API_URL}send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(response => {
        debugger;
        return response.json();
      })
      .catch(function(error) {
        console.log(error);
        return null;
      });
  }
};
