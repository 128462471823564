import { Component } from 'react';
import * as React from 'react';
import { ContentWithImage } from 'src/types';
import { getActiveLanguageUrlCode } from 'src/helpers/languageHelper';
import { Link } from 'react-router-dom';

type Props = {
	layout: ContentWithImage;
};

const hoverStyle = {
	transition: 'background 1s',
	background: '#000',
	opacity: 0.7
};
const easeIn = {
	transition: 'all 700ms',
	marginLeft: '0px'
};
const widthZero = {
	marginLeft: '-40px'
};
class HeroWrapperContent extends Component<Props, any> {
	box1: any;
	model: any;
	// hovered: boolean;
	constructor(props: any) {
		super(props);
		this.state = { hovered: false };
		this.onHover = this.onHover.bind(this);
	}
	onHover(hovered: boolean) {
		this.setState({ hovered });
	}
	componentDidMount() { }

	render() {
		const model = this.props.layout && this.props.layout.fields;
		const imageUrl = model && model.image && model.image.fields.file.url;
		const title = model && this.props.layout.fields.title;
		const slug = model && this.props.layout.fields.pageLink.fields && this.props.layout.fields.pageLink.fields.slug;

		return (
			<Link
				to={'/' + getActiveLanguageUrlCode() + '/' + slug}
				style={{ backgroundImage: `url(${imageUrl})` }}
				onMouseOver={() => this.onHover(true)}
				onMouseLeave={() => this.onHover(false)}
				className="contentmainpage w-inline-block"
			>
				<div className="div-block-62" style={this.state.hovered ? hoverStyle : {}}>
					&nbsp;
				</div>
				<div className="div-block-34" style={{ paddingTop: "1px" }}>
					<div
						style={{
							marginLeft: '-50px',
							position: 'absolute',
							width: '50px',
							height: '50px',
							backgroundColor: 'white',
							zIndex: 100
						}}
					/>
					<div style={!this.state.hovered ? widthZero : easeIn} className="arrowicon mainpagepicture" />

					{/* </div> */}
					<h1 className="h4 bold menu _2">{title}</h1>
				</div>
			</Link>
		);
	}
}
export default HeroWrapperContent;
