import { AvailableGyms } from "src/types";

type gyms = { name: 'sickla'; adress: "Marcusplatsen 14"; postAdress: "131 34 Nacka"; googleadress: "https://www.google.com/maps/place/Kl%C3%A4tterverket/@59.305273,18.119031,17z/data=!3m1!4b1!4m5!3m4!1s0x465f7812cc187873:0x6d0506db2703e53!8m2!3d59.3052703!4d18.1212197" } |
{ name: 'gasverket'; adress: "Bobergsgatan 18"; postAdress: "115 44 Stockholm"; googleadress: "https://www.google.com/maps/place/Gasverket/@59.3544238,18.0866715,19.39z/data=!4m5!3m4!1s0x0:0xd069887229718114!8m2!3d59.3549881!4d18.0870076" };

export type chooseGym = 'sickla' | 'gasverket';
export const sickla = {
	id: 1,
	name: "sickla",
	adress: "Marcusplatsen 17",
	postAdress: "131 34 Nacka",
	googleAdress: "https://www.google.com/maps/place/Kl%C3%A4tterverket/@59.305273,18.119031,17z/data=!3m1!4b1!4m5!3m4!1s0x465f7812cc187873:0x6d0506db2703e53!8m2!3d59.3052703!4d18.1212197"
}
export const gasverket = {
	id: 2,
	name: "gasverket",
	adress: "Gasverksvägen 15",
	postAdress: "115 42 Stockholm",
	googleAdress: "https://www.google.com/maps/place/Gasverksv%C3%A4gen+15,+115+42+Stockholm/@59.3569177,18.0874505,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9d3b7b438b17:0x126e5e7689985c21!8m2!3d59.356915!4d18.0896392"
}

export const getGyms = (): any[] => {
	let arr: any[] = [];
	arr.push(sickla);
	arr.push(gasverket);
	return arr;
}

type GymContainer = {
	gym: AvailableGyms,
	showBackdrop: boolean
}

export const getActiveGym = (): GymContainer => {
	if (sessionStorage.getItem('gym') === 'sickla') {
		return { showBackdrop: false, gym: 'sickla' }
	} else if (sessionStorage.getItem('gym') === "gasverket") {
		return { showBackdrop: false, gym: 'gasverket' }
	}
	return { showBackdrop: true, gym: null }
};

export const setActiveGym = (code: chooseGym = 'sickla') => {
	if (code === "sickla") {
		sessionStorage.setItem('gym', code);
	}
	else {
		sessionStorage.setItem('gym', code);

	}
};



