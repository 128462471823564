import * as React from "react";

export const HeaderWithTitle = (props: any) => {
  const { parentTitle, hideParentTitle } = props.fields;
  const title = props.title && props.title.charAt(0).toUpperCase() + props.title.slice(1);
  const subtitle = !title ? props.fields.title.charAt(0).toUpperCase() + props.fields.title.slice(1) : undefined;

  return (
    <div className="headercontent">
      {title && !hideParentTitle && !parentTitle && <h1 className="h1 bold center">{title}</h1>}
      {!hideParentTitle && parentTitle && <h1 className="h1 bold center">{parentTitle}</h1>}
      <div className="div-block-61">
        <h1 className="h1 bold center">
          {(title || parentTitle) && !hideParentTitle && <div style={{ display: "inline-block" }} className="text-block dash">/</div>}
          {subtitle ? subtitle : props.fields.title}
        </h1>
      </div>
    </div>
  )
};