import { Component } from 'react';
import * as React from 'react';
import { rotateStyleDown, rotateStyleUp, transitionIn } from './templates/ExpandablePagesInPageTemplate';

class BottomShade extends Component<any, any> {
	constructor(props: any) {
		super(props);
	}

	render() {
		let showAllNews = false;
		let isStartPage = this.props.allNewsExpanded === undefined;
		if (isStartPage) {
			showAllNews = this.props.showAllNews;
		} else {
			showAllNews = this.props.allNewsExpanded;
		}
		let arrowShouldBeUp = showAllNews || this.props.allNewsExpanded;
		const showNewsStyle = arrowShouldBeUp || this.props.fewItems ? { backgroundImage: 'none', marginTop: isStartPage ? '-150px' : '0px' } : {};
		const rotateNewsStyle = this.props.hasChanged && !this.props.allNewsExpanded ? rotateStyleDown(900) : arrowShouldBeUp ? rotateStyleUp(900) : {};
		return (
			<div className={this.props.numberOfItems !== 3 ? 'div-block-12' : ''} style={this.props.numberOfItems !== 3 ? showNewsStyle : {}} id="block-container">
				{/* <div className="div-block-51 nomargin" /> */}
				{this.props.numberOfItems > 3 && <div className="div-block-51 nomargin" />}
				<div className="shadedarrow">
					{!this.props.fewItems && (
						<div
							onClick={() => this.props.showHideNews(this.props.allNewsExpanded === undefined ? !this.props.showAllNews : showAllNews)}
							style={rotateNewsStyle}
							className="arrowicon big black"
						/>
					)}
				</div>
			</div>
		);
	}
}
export default BottomShade;