import { Component } from 'react';
import * as React from 'react';
import Navbar from '../Navbar';
import Menu from '../Menu';
import Footer from '../Footer';
import ChooseGym from '../ChooseGym';
let $ = require('jquery');

class MasterTemplate extends Component<any, any> {
	width: number;
	constructor(props: any) {
		super(props);
		this.state = { menuOpen: false, title: '' };
		this.onClickMenu = this.onClickMenu.bind(this);
		this.width = 767;
	}

	componentDidMount() {
		if (this.props.location.state && this.props.location.state.prevPath !== this.props.location.pathname) {
			// normal scroll
			$(window).off('scroll');
		}
	}

	onClickMenu() {
		this.setState({ menuOpen: !this.state.menuOpen });
		// if ($(window).width() < this.width) {
		// 	if (this.state.menuOpen === false) {
		// 		let current = $(window).scrollTop();
		// 		$(window).scroll(function () {
		// 			$(window).scrollTop(current);
		// 		});
		// 	}
		// 	else {
		// 		// normal scroll
		// 		$(window).off('scroll');
		// 	}
		// }
	}

	public render() {
		return (
			<div>
				<Navbar onClickMenu={this.onClickMenu} />
				<Menu onClickMenu={this.onClickMenu} {...this.props} menuOpen={this.state.menuOpen} />
				{this.props.children}
				<Footer />
			</div>
		);
	}
}
export default MasterTemplate;
