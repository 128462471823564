import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { LayoutTypes } from 'src/types';
import { ContentType } from 'contentful';

const applyMarks = (content: any): string => {
	let openingTags = '';
	let closingTags = '';
	const marks = content.marks;
	if (marks) {
		for (const mark of marks) {
			switch (mark.type) {
				case 'bold':
					openingTags += '<b>';
					closingTags += '</b>';
					break;
				case 'italic':
					openingTags += '<i>';
					closingTags += '</i>';
					break;
				case 'underline':
					openingTags += '<u>';
					closingTags += '</u>';
					break;
			}
		}
	}
	return openingTags + content.value + closingTags;
}

const contentRecursive = (node: any, result: string): string => {
	for (const item of node.content) {
		if (item.content) {
			result += contentRecursive(item, result);
		}
		else {
			return result += handleNode(node);
		}
	}
	return result;
}

const handleNode = (node: any) => {
	let openingTags = '';
	let closingTags = '';
	let resultHtml = '';

	let contentType: LayoutTypes | ContentType = 'none';
	for (let content of node.content) {
		if (content.marks && content.marks.length > 0) {
			resultHtml += applyMarks(content);
		} else {
			let value = content.value;
			if (content.content) {
				if (content.nodeType === 'hyperlink') {
					value = `<a href='${content.data.uri}'>`;
					for (const item of content.content) {
						value += item.value;
					}
					value += '</a>';
				}
			}
			if (content.nodeType === 'hyperlink') {
				value = `<a href='${content.data.uri}'>`;
				for (const item of content.content) {
					value += item.value;
				}
				value += '</a>';
			}
			// console.log(content);
			if (content.nodeType === 'entry-hyperlink') {
				let slug = content.data.target.fields && content.data.target.fields.slug;
				value = `<a href='${slug}'>${content.content[0].value}</a>`;
			}
			if (content.nodeType === 'embedded-entry-inline') {
				let fields = content.data.target.fields;
				contentType = content.data.target.sys.contentType.sys.id;
				// console.log(content.data.target);
				if (contentType === 'button') {
					console.log(fields);
					let linkUrl = fields.page && fields.page.fields.slug;
					let url = fields.hyperlink ? fields.hyperlink : '';
					value = `<a href='${linkUrl ? linkUrl : url}' class='button w-inline-block'>${fields.title}</a>`;
				}
				if (contentType === 'page') {
					let slug = content.data.target.fields.slug;
					value = `<a href='${slug}' style="cursor:pointer">${fields.title}</a>`;
				}
				if (contentType === 'news') {
					let slug = content.data.target.fields.page.fields.slug;
					if (slug) {
						value = `<a href='/${slug}' style="cursor:pointer">${fields.title}</a>`;
					}
					else {
						debugger;
						let id = content.data.target.sys.id;
						value = `<a href='/nyhetssidan/${id}' style="cursor:pointer">${fields.title}</a>`;
					}
				}
				if (contentType === 'imageWithLink') {
					let imageUrl = fields.image.fields.file.url;
					let imageTitle = fields.image.fields.file.url;
					let linkUrl = fields.link && fields.link.fields.slug;
					let url = fields.url ? fields.url : '';

					let width = fields.width && `width:${fields.width}px;`;
					let height = fields.height && `width:${fields.height}px;`;
					let imgStyle = fields.width || fields.height ? `style="${fields.width ? width : ''}${fields.height ? height : ''}"` : '';

					value = `<a href='${linkUrl ? linkUrl : url}'><img ${imgStyle} src='${imageUrl}' title='${imageTitle}' /></a>`;
				} else if (contentType === 'layoutStandardWysywyg') {
					value = fields.description;
					// console.log(value);
				}
			}

			resultHtml += openingTags + value + closingTags;
		}
	}

	if (contentType !== 'layoutStandardWysywyg') {
		resultHtml = resultHtml.replace(/(?:\r\n|\r|\n)/g, '<br/>');
	}
	// node.
	if (node.nodeType.startsWith("heading")) {
		return resultHtml === '' ? '&nbsp;' : resultHtml
	}
	if (node.nodeType === "paragraph") {
		return `<p>${resultHtml}</p>`
	}
	return resultHtml === '' ? '&nbsp;' : `<p>${resultHtml}</p>`
}

function generateHtmlList(htmlElementType: 'ul' | 'ol', node: any) {
	const ulList = node.content;
	let listOfLiStr = '';
	for (const item of ulList) {
		let innerItemContent = "";
		innerItemContent = contentRecursive(item, "");
		listOfLiStr += `<li>${innerItemContent}</li>`;
	}

	return `<${htmlElementType}>${listOfLiStr}</${htmlElementType}>`;
}
export const defaultOptions = {
	renderNode: {
		[BLOCKS.PARAGRAPH]: handleNode,
		// [BLOCKS.EMBEDDED_ENTRY]: (node: any) => `<div>hej</div>`,
		// [INLINES.ENTRY_HYPERLINK]: (node: any) => `<div>heja</div>`,
		[BLOCKS.EMBEDDED_ASSET]: (node: any) => {
			console.log(node);
			const url = node.data.target.fields.file.url;
			const fileName = node.data.target.fields.file.fileName;
			const title = node.data.target.fields.title;
			// const contentType = node.data.target.fields.file.contentType;
			const image = node.data.target.fields.file.details.image;
			const contentType = node.data.target.fields.file.contentType;
			// console.log(image);
			if (contentType.startsWith("image/")) {
				return `<img alt='${fileName}' src='${url}?fit=scale'/>`;
			}
			else if (contentType === "application/pdf") {
				return `<a target='_blank' href='${url}'>${title}</a>`;
			}
			else {
				return '';
			}
			// console.log('test');
		},
		[BLOCKS.HEADING_1]: (node: any) => `
        <h1>
			${handleNode(node)}
        </h1>`,
		[BLOCKS.HEADING_2]: (node: any) => `
        <h2>
			${handleNode(node)}
        </h2>`,
		[BLOCKS.HEADING_3]: (node: any) => `
        <h3>
			${handleNode(node)}
        </h3>`,
		[BLOCKS.HEADING_4]: (node: any) => `
        <h4>
          ${handleNode(node)}
        </h4>`,
		[BLOCKS.HEADING_5]: (node: any) => `
        <h5>
			${handleNode(node)}
        </h5>`,
		[BLOCKS.HEADING_6]: (node: any) => `
        <h6>
			${handleNode(node)}
        </h6>`,
		[BLOCKS.HR]: (node: any) => `<hr />`,
		[BLOCKS.LIST_ITEM]: null,
		[BLOCKS.OL_LIST]: (node: any) => generateHtmlList("ol", node),
		[BLOCKS.QUOTE]: null,
		[BLOCKS.UL_LIST]: (node: any) => generateHtmlList("ul", node)
	}
};
