import { Component } from 'react';
import * as React from 'react';
import { ImageFields } from 'src/types';
import { defaultOptions } from 'src/contentful/helper';
import { HeaderWithTitle } from './HeaderWithTitle';
const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');
// import { LayoutItem } from 'src/types';

class TwoColumnsTopThreeBottomTemplate extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = { html: '' };
	}
	componentDidMount() {
		const title = this.props.fields.title;
		const slug = this.props.fields.slug;
		const layout = this.props.fields.layout;
		console.log(layout);

		let leftContentTop = '';
		let rightContentTop = '';
		let leftContentBottom = '';
		let middleContentBottom = '';
		let rightContentBottom = '';

		if (layout.fields.leftContentTop) {
			leftContentTop = documentToHtmlString(layout.fields.leftContentTop, defaultOptions);
		}
		if (layout.fields.rightContentTop) {
			rightContentTop = documentToHtmlString(layout.fields.rightContentTop, defaultOptions);
		}

		if (layout.fields.leftContentBottom) {
			leftContentBottom = documentToHtmlString(layout.fields.leftContentBottom, defaultOptions);
		}
		if (layout.fields.middleContentBottom) {
			middleContentBottom = documentToHtmlString(layout.fields.middleContentBottom, defaultOptions);
		}
		if (layout.fields.rightContentBottom) {
			rightContentBottom = documentToHtmlString(layout.fields.rightContentBottom, defaultOptions);
		}

		this.setState({ title, slug, html: 'result', leftContentTop, rightContentTop, leftContentBottom, middleContentBottom, rightContentBottom });
	}

	render() {
		const leftContentTopHtml = <div dangerouslySetInnerHTML={{ __html: this.state.leftContentTop }} />;
		const rightContentTopHtml = <div dangerouslySetInnerHTML={{ __html: this.state.rightContentTop }} />;

		const leftContentBottomHtml = <div dangerouslySetInnerHTML={{ __html: this.state.leftContentBottom }} />;
		const middleContentBottomHtml = <div dangerouslySetInnerHTML={{ __html: this.state.middleContentBottom }} />;
		const rightContentBottomHtml = <div dangerouslySetInnerHTML={{ __html: this.state.rightContentBottom }} />;
		return (
			<React.Fragment>
				<div className="contentwrapper">
					<div className="div-block-37" id={'single-news-container' + this.props.index}>
						<div>
							<HeaderWithTitle {...this.props} />
						</div>
						<div className="div-block-57">
							<div className={this.state.wideLeft ? 'leftcolumn big' : 'leftcolumn'}>{leftContentTopHtml}</div>
							<div className={!this.state.wideLeft ? 'rightcolumn' : 'rightcolumn small'}>{rightContentTopHtml}</div>
						</div>
						<div className="div-block-57">
							<div className={'leftcolumn three'}>{leftContentBottomHtml}</div>
							<div className={'middlecolumn three'}>{middleContentBottomHtml}</div>
							<div className={'rightcolumn three'}>{rightContentBottomHtml}</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default TwoColumnsTopThreeBottomTemplate;
