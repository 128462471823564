import { ListRoot, LayoutTypes, LayoutItem } from '../types';

export type ParseResultObj = {
	layout: LayoutItem;
	layoutType: LayoutTypes;
	title: string;
	slug?: string;
};

export const parseResult = (result: ListRoot): ParseResultObj => {
	const layout = result.items[0].fields.layout;

	// var layoutType = layout.sys.contentType && layout.sys.contentType.sys.id;

	let layoutType: LayoutTypes = 'none';

	if (layout && layout.sys.contentType) {
		switch (layout.sys.contentType.sys.id) {
			case 'layoutStart':
				layoutType = 'layoutStart';
			case 'layout':
				layoutType = 'layout';
		}
	}

	// const layoutType: LayoutTypes =
	// 	layout.sys.contentType && layout.sys.contentType.sys.id === "start"
	// 		? LayoutTypes.second
	// 		: LayoutTypes.first;
	// debugger;
	return { layout, layoutType, title: layout.fields.title, slug: result.items[0].fields.slug };
	// return ;
};
