import { Component } from 'react';
import * as React from 'react';
import { NewsItem } from './NewsHomeWrapper';
import Moment from 'moment';
import { getActiveLanguageUrlCode } from 'src/helpers/languageHelper';
import { Link } from 'react-router-dom';
let $ = require('jquery');

type NewsProps = {
	item: NewsItem;
	showDate: boolean;
	index: number;
	homePage: boolean;
	getHeight?: any;
};

class NewsHomeComponent extends Component<NewsProps, any> {
	componentDidMount() {
		// console.log($("#inner-block-container").height())
		if (this.props.index === 2) {
			let element1 = $('#inner-block-container .mainpagenewswrapper:eq(0)').height();
			let element2 = $('#inner-block-container .mainpagenewswrapper:eq(1)').height();
			let element3 = $('#inner-block-container .mainpagenewswrapper:eq(2)').height();
			if (this.props.getHeight) {
				this.props.getHeight(element1 + element2 + element3);
			}
			// console.log(element);
		}
		// let secondElementHeight = $(".mainpagenewswrapper:eq(1)").height()
		// let thirdElementHeight = $(".mainpagenewswrapper:eq(2)").height()
		// console.log(firstElementHeight);
		// console.log(secondElementHeight);
		// console.log(thirdElementHeight);
		console.log(this.props.index);
	}

	render() {
		const page = this.props.item.fields.page;
		let pageUrl = '/' + getActiveLanguageUrlCode() + '/';
		pageUrl += page ? page.fields.slug : 'nyhetssidan/' + this.props.item.sys.id;

		return (
			<div className="mainpagenewswrapper">
				<div className="div-block-10">
					<h3 className="h4 bold">{this.props.item.fields.title}</h3>
				</div>
				<div className="div-block-11" style={this.props.homePage && this.props.index !== 2 ? { maxWidth: '300px', zIndex: 10 } : { maxWidth: '300px' }}>
					{this.props.showDate && (
						<div>
							<div>
								<h4 className="h4 bold">{Moment(this.props.item.fields.fromDate).format('Y-MM-DD')}</h4>
							</div>
						</div>
					)}
					<div>
						<p className="p">{this.props.item.fields.introText}</p>
					</div>
					<Link to={pageUrl} className="link-block w-inline-block">
						<h4 className="h4 bold underscore">Läs mer</h4>
					</Link>
				</div>
			</div>
		);
	}
}
export default NewsHomeComponent;
