import * as React from 'react';
import { Component } from 'react';
import { getActiveGym, setActiveGym, getGyms } from 'src/helpers/gymHelper';
import { Link } from 'react-router-dom';


class ChooseGym extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showBackdrop: true,
            gym: getGyms()

        }
    }
    onChooseGym = (gym: any, ) => {

        // ta bort ifSatsen när Gasverket blir up and running, ta bort även let gasverket och sickla. dljl
        // if (gym.id === 1) {
        //     setActiveGym(gym.name)
        //     this.setState({ showBackdrop: false })
        // } else {
        //     setActiveGym("sickla")
        //     // return <Link to= />
        // }
        setActiveGym(gym.name)
        this.setState({ showBackdrop: false })
        // let redirect = gym.id === 2 ? "/sv/klaetterverket-gasverket" : "/"
        window.location.href = "/";
    }

    componentDidMount() {

        let checkGym = getActiveGym()
        this.setState({ showBackdrop: checkGym.showBackdrop })
    }

    render() {
        let backdrop = this.state.showBackdrop ? "backdrop" : "backdrop hidden "
        return (
            <div className={backdrop}>
                <div className="div-block-72">
                    <div className="div-block-73">
                        {this.state.gym.map((gym: any, index: any) => {

                            return (
                                <div
                                    className="div-block-74"
                                    key={index}
                                >
                                    <div className="navbarheader firstpage"
                                        onClick={() => this.onChooseGym(gym)}
                                    >
                                        <div>
                                            <h1 className="hoover bold">Klätterverket<br /></h1>
                                        </div>
                                        <div className="div-block-61">
                                            <div>
                                                <h1 className="hoover bold">/{gym.name}<br /></h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div-block-85">
                                        <div className="div-block-76">
                                            <p className="paragrafsmall">{gym.adress}<br />{gym.postAdress}</p>
                                            <div className="findmap">
                                                <div className="div-block-78">
                                                    <div className="text-block-2"></div>
                                                </div>
                                                <div className="div-block-79">
                                                    <object>
                                                        <a href={gym.googleAdress} target="_blank">Vägbeskrivning</a>

                                                    </object>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ChooseGym;