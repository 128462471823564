import { Component } from 'react';
import * as React from 'react';
import { HeaderWithTitle } from './HeaderWithTitle';
// import { BLOCKS, INLINES } from '@contentful/rich-text-types';
// import { defaultOptions } from 'src/contentful/helper';
// const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');

class WysiwygTemplate extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = { menuOpen: false, title: '' };
	}
	componentDidMount() {
		const title = this.props.fields.title;
		const slug = this.props.fields.slug;
		const description = this.props.fields.layout.fields.description;
		this.setState({ title, slug, html: description });
	}
	componentWillReceiveProps() {
	}
	public render() {
		// let i = 0;
		return (
			<div className="contentwrapper">
				<div className={!this.props.hideWrapper ? 'newscontentmainwrapper div-block-37' : 'div-block-37'} id={'single-news-container' + this.props.index}>
					<div>
						<HeaderWithTitle {...this.props} />
						<div dangerouslySetInnerHTML={{ __html: this.state.html }} />
					</div>
					<div dangerouslySetInnerHTML={{ __html: this.state.html }} />
				</div>
			</div>
			// </div>
		);
	}
}
export default WysiwygTemplate;
