import { Component } from 'react';
import * as React from 'react';
import { contentful } from 'src/services/contentful';
import { LayoutTypes } from 'src/types';
import DefaultDynamicTemplate from './DefaultDynamicTemplate';
import WysiwygTemplate from './WysiwygTemplate';
import AdvancedDynamicTemplate from './AdvancedDynamicTemplate';
import VerticalImagesAndTextTemplate from './VerticalImagesAndTextTemplate';
import TwoColumnsTopThreeBottomTemplate from './TwoColumnsTopThreeBottomTemplate';
import DefaultDynamicTemplateInner from './DefaultDynamicTemplateInner';

const speedProportion: number = 1000;

export const rotateStyleUp = (clientHeight?: number, speedProp: number = speedProportion): any => {
	if (clientHeight) {
		return {
			transitionDelay: '0.15s',
			WebkitTransform: 'rotate(180deg)',
			MozTransform: 'rotate(180deg)',
			MsTransform: 'rotate(180deg)',
			OTransform: 'rotate(180deg)',
			WebkitTransitionDuration: clientHeight / speedProportion + 's',
			MozTransitionDuration: clientHeight / speedProportion + 's',
			OTransitionDuration: clientHeight / speedProportion + 's',
			TransitionDuration: clientHeight / speedProportion + 's',
			zIndex: 100
		};
	}
};
export const rotateStyleDown = (clientHeight?: number): any => {
	if (clientHeight) {
		return {
			transitionDelay: '0.15s',
			WebkitTransitionDuration: clientHeight / speedProportion + 's',
			MozTransitionDuration: clientHeight / speedProportion + 's',
			OTransitionDuration: clientHeight / speedProportion + 's',
			TransitionDuration: clientHeight / speedProportion + 's',
			zIndex: 100
		};
	}
};
export const transitionIn = (clientHeight?: number): any => {
	return clientHeight ? 'max-height ' + (clientHeight / speedProportion - 0.1) + 's ease-in-out' : 'max-height 1s ease-in-out';
};

class ElementWithChildren extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.showHideContent = this.showHideContent.bind(this);
		this.state = { showContent: false };
	}
	showHideContent() {
		this.setState({ showContent: !this.state.showContent });
	}

	componentDidMount() { }
	render() {
		const expandContent = this.state.showContent;
		let element = document.getElementById('single-news-container' + this.props.index);
		let clientHeight = element && element.clientHeight;
		let clientHeightNumber = clientHeight ? clientHeight : undefined;

		const childrenWithProps = React.Children.map(this.props.children, (child) => React.cloneElement(child as React.ReactElement<any>, { index: this.props.index }));

		return (
			<div className="div-block-56">
				<div
					style={
						expandContent ? (
							{
								transition: transitionIn(clientHeightNumber),
								maxHeight: clientHeight ? clientHeight.toString() + 'px' : '',
								marginTop: 48,
								marginBottom: 48,
								overflow: 'hidden'
							}
						) : (
								{
									transition: transitionIn(clientHeightNumber),
									maxHeight: '300px',
									marginTop: 48,
									marginBottom: 48,
									overflow: 'hidden'
								}
							)
					}
				>
					{childrenWithProps}
				</div>
				<div className="div-block-12" style={expandContent ? { backgroundImage: 'none' } : {}}>
					<div className="shadedarrow" style={expandContent ? {} : {}}>
						<div
							onClick={this.showHideContent}
							style={expandContent ? rotateStyleUp(clientHeightNumber) : rotateStyleDown(clientHeightNumber)}
							className="arrowicon big black"
						/>
					</div>
					<div className="div-block-51 nomargin" />
				</div>
			</div>

		);
	}
}

class ExpandablePagesInPageTemplate extends Component<any, any> {
	constructor(props: any) {
		super(props);
		const pages = props.fields.layout.fields.pages;
		this.state = { menuOpen: false, title: '', showContent: false };
		this.showHideContent = this.showHideContent.bind(this);
	}
	componentDidMount() {
		const title = this.props.fields.title;
		const slug = this.props.fields.slug;
		const description = this.props.fields.layout.fields.description;
		this.setState({ title, slug, html: description });
	}
	componentWillReceiveProps() { }
	showHideContent(index: number) {
		const selectedIndex = this.state.selectedIndex ? this.state.selectedIndex : index;
		this.setState({
			showContent: !this.state.showContent && selectedIndex === index,
			selectedIndex: index
		});
		console.log('show hide content');
	}
	public render() {
		debugger;
		// let i = 0;
		const pages = this.props.fields.layout.fields.pages;
		const title = this.props.fields.title;
		// const { hideParentTitle, parentTitle } = this.props.fields;
		let index = 0;
		return (
			<div className="contentwrapper">
				{pages.map((page: any) => {
					index++;
					const { hideParentTitle, parentTitle } = page.fields;
					const contentType: LayoutTypes = page.fields && page.fields.layout.sys.contentType.sys.id;
					switch (contentType) {
						case 'layout':
							return (
								<ElementWithChildren index={index}>
									<DefaultDynamicTemplateInner hideParentTitle={hideParentTitle} parentTitle={parentTitle} hideWrapper={true} fields={page.fields} title={title} />
								</ElementWithChildren>
							);
						case 'layoutStandardWysywyg':
							return (
								<ElementWithChildren index={index}>
									<WysiwygTemplate hideParentTitle={hideParentTitle} parentTitle={parentTitle} hideWrapper={true} fields={page.fields} title={title} />
								</ElementWithChildren>
							);
						case 'layoutTextAndImagesVerticallyWysiwyg':
							return (
								<ElementWithChildren index={index}>
									<VerticalImagesAndTextTemplate hideParentTitle={hideParentTitle} parentTitle={parentTitle} hideWrapper={true} fields={page.fields} title={title} />
								</ElementWithChildren>
							);
						case 'layoutDynamic':
							return (
								<ElementWithChildren index={index}>
									<AdvancedDynamicTemplate hideParentTitle={hideParentTitle} parentTitle={parentTitle} hideWrapper={true} fields={page.fields} title={title} />
								</ElementWithChildren>
							);
						case 'layoutTextAndImagesVertically':
							return (
								<ElementWithChildren index={index}>
									<VerticalImagesAndTextTemplate hideParentTitle={hideParentTitle} parentTitle={parentTitle} fields={page.fields} title={title} />
								</ElementWithChildren>
							);
						case 'layout2Columns3Columns':
							return (
								<ElementWithChildren index={index}>
									<TwoColumnsTopThreeBottomTemplate hideParentTitle={hideParentTitle} parentTitle={parentTitle} fields={page.fields} title={title} />
								</ElementWithChildren>
							);
						default:
							return (
								<ElementWithChildren index={index}>
									<DefaultDynamicTemplate hideParentTitle={hideParentTitle} parentTitle={parentTitle} hideWrapper={true} title={title} fields={page.fields} />
								</ElementWithChildren>
							);
					}
				})}
			</div>
		);
	}
}
export default ExpandablePagesInPageTemplate;
