const contentful = require('contentful');
type ContentfulApi = {
	getSpace: () => any;
	getContentType: () => any;
	getContentTypes: () => any;
	getEntry: (para: any) => any;
	getEntries: (para: any) => any;
	getAsset: () => any;
	getAssets: () => any;
	parseEntries: () => any;
	sync: () => any;
};
// CDAEntry cda = CDAEntry;
export const contentfulClient: ContentfulApi = contentful.createClient({
	space: '75jnlcutv3bk',
	accessToken: '74f19b91a3024c50da9c677790512ce83b32e27141e3c6538905cf5281921da3'
});
