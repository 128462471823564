type languages = { cultureCode: 'en-GB'; shortUrlCode: 'en' } | { cultureCode: 'sv-SE'; shortUrlCode: 'sv' };
export type cultureCodes = 'en-GB' | 'sv-SE';
export const availableLanguages = {
	swedish: { cultureCode: 'sv-SE', shortUrlCode: 'sv' },
	english: { cultureCode: 'en-GB', shortUrlCode: 'en' }
};
export const getActiveLanguage = (): languages => {
	if (localStorage.getItem('active-language') === 'en-GB') {
		return { shortUrlCode: 'en', cultureCode: 'en-GB' };
	} else {
		return { shortUrlCode: 'sv', cultureCode: 'sv-SE' };
	}
};

export const getActiveLanguageUrlCode = (): string => {
	return getActiveLanguage().shortUrlCode;
};

export const setActiveLanguageByCultureCode = (culture: string = 'sv-SE') => {
	localStorage.setItem('active-language', culture);
};

export const setActiveLanguageShortUrlCode = (code: string = 'sv') => {
	let culture =
		availableLanguages.english.shortUrlCode === code
			? availableLanguages.english.cultureCode
			: availableLanguages.swedish.cultureCode;
	localStorage.setItem('active-language', culture);
};
