import { Component } from 'react';
import * as React from 'react';
import NewsHomeComponent from './NewsHomeComponent';
import BottomShade from './BottomShade';
import { Sys } from 'src/types';
import { Banner } from 'src/DynamicPage';
import { getActiveLanguageUrlCode } from 'src/helpers/languageHelper';
import { Link } from 'react-router-dom';
import { transitionIn } from './templates/ExpandablePagesInPageTemplate';

const backgroundImageStyle = (imageUrl: string) => {
	return {
		backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, .76), rgba(0, 0, 0, .76)),url("${imageUrl}")`
	};
};

const bannerTextStyle = {
	overflow: 'hidden',
	// display: '-webkit-box',
	webkitLineClamp: '3',
	webkitBoxOrient: 'vertical',
	minHeight: '160px'
};

class HeroPromotion extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { showNews: false };
  }
  render() {
	const banner = this.props.banner ? this.props.banner : null;
	const styleBackground = banner && banner.imageBackground ? backgroundImageStyle(banner.imageBackground.fields.file.url) : {};

    return (
      <>
        {this.props.banner && (
          <div
            className="mainpagenewswrapper important"
            style={styleBackground}
          >
            <div className="div-block-10">
              <h4 className="h2 white important">{this.props.banner.title}</h4>
            </div>
            <div className="div-block-11">
              <div style={bannerTextStyle}>
                <p>{this.props.banner.text}</p>

                {this.props.banner.page && (
                  <Link
                    to={
                      '/' +
                      getActiveLanguageUrlCode() +
                      '/' +
                      this.props.banner.page.fields.slug
                    }
                    className="link-block"
                  >
                    <h4 className="h4 bold underscore white">Läs mer</h4>
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default HeroPromotion;
