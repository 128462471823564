import { Component } from 'react';
import * as React from 'react';
import { contentful } from '../services/contentful';
import { parseResult, ParseResultObj } from '../services/parsehelper';
import { ListRoot } from 'src/types';
import HeroWrapperContent from './HeroWrapperContent';
import { getActiveGym } from 'src/helpers/gymHelper';
// import { ListRoot } from 'src/types';

class HeroWrapper extends Component<any, ParseResultObj> {
	constructor(props: any) {
		super(props);
		// this.state = {layout:,title:""};
	}
	componentDidMount() {
		let slug: string = this.props.slug;
		if (getActiveGym().gym === 'gasverket') {
			slug = 'home-gasverket';
		}


		contentful.getPageBySlug(slug).then((result: ListRoot) => {
		// });
		// contentful.getPageBySlug(this.props.slug).then((result: ListRoot) => {
		// 	console.log(result.items[0].sys.id);
		// 	slug = result.items[0].fields.slug;
			contentful.getEntryById(result.items[0].sys.id).then((innerResult) => {
				const res = parseResult(innerResult);
				this.setState({ layout: res.layout, title: res.title, layoutType: res.layoutType, slug });
			});
		});
	}
	render() {
		const layout = this.state && this.state.layout;
		return (
			<div>
				<div className="mainpagecontent">
					<div className="contentmainpagewrapper">
						<div className="div-block-16">
							<HeroWrapperContent layout={layout && layout.fields.boxOne} />
							<HeroWrapperContent layout={layout && layout.fields.boxTwo} />
							<HeroWrapperContent layout={layout && layout.fields.boxThree} />
							<HeroWrapperContent layout={layout && layout.fields.boxFour} />
						</div>
						<div className="div-block-51" />
					</div>
				</div>
			</div>
		);
	}
}
export default HeroWrapper;
