import * as React from 'react';
import { Component } from 'react';
import { contentful } from './services/contentful';
import { ListRoot, LayoutTypes, LayoutFields, Languages } from './types';
import HeroWrapper from './components/HeroWrapper';
import NewsHomeWrapper from './components/NewsHomeWrapper';
import SingleNewsPageTemplate from './components/templates/SingleNewsPageTemplate';
import DefaultDynamicTemplate from './components/templates/DefaultDynamicTemplate';
import VerticalImagesAndTextTemplate from './components/templates/VerticalImagesAndTextTemplate';
import AdvancedDynamicTemplate from './components/templates/AdvancedDynamicTemplate';
import NewsPageTemplate from './components/templates/NewsPageTemplate';
import WysiwygTemplate from './components/templates/WysiwygTemplate';
import ExpandablePagesInPageTemplate from './components/templates/ExpandablePagesInPageTemplate';
import { setActiveLanguageShortUrlCode } from './helpers/languageHelper';
import TwoColumnsTopThreeBottomTemplate from './components/templates/TwoColumnsTopThreeBottomTemplate';
import ChooseGym from './components/ChooseGym';
import PricePage from './components/templates/PricePage';
import GalleryPageTemplate from './components/templates/GalleryPageTemplate';
import PricePageDynamic from './components/PricePageDynamic';
import HeroPromotion from './components/HeroPromotion';
let _ = require('lodash');

type AppState = {
	// layout?: LayoutItem;
	layoutType: LayoutTypes;
	title: string;
	// menuOpen: boolean;
	slug?: string;
	newsItems?: any;
	item?: any;
	showNews: boolean;
	hasChanged?: boolean;
	banner?: Banner;
};
export type Banner = {
	text: string;
	title: string;
	visible: boolean;
	page: any;
	imageBackground: any;
	position: string[];
};

class Page extends Component<any, AppState> {
	layoutType: LayoutTypes = 'none';
	fields: LayoutFields;

	constructor(props: any) {
		super(props);

		let lang: Languages = props.match.params.lang;
		if (lang === 'sv' || lang === 'en') {
			setActiveLanguageShortUrlCode(props.match.params.lang);
		}
		console.log(this.layoutType);

		this.state = {
			// menuOpen: false,
			title: '',
			layoutType: this.layoutType,
			showNews: false
		};
		// this.onClickMenu = this.onClickMenu.bind(this);
		this.showHideNews = this.showHideNews.bind(this);

		console.log('read');
		contentful
			.getEntryById(props.id)
			.then((result: ListRoot) => {
				const fields: LayoutFields = result.items[0].fields;
				const title = fields.title;
				const slug = fields.slug;

				if (result.items[0].fields.layout && result.items[0].fields.layout.sys.contentType) {
					this.layoutType = result.items[0].fields.layout.sys!.contentType!.sys.id;
				}

				const layoutType = result.items[0].fields.layout && result.items[0].fields.layout.sys!.contentType!.sys.id;
				this.setState({
					title,
					slug,
					item: result.items[0],
					layoutType
				});
				if (this.state.slug === 'home') {
					contentful.getNews('fromDate').then((newsResults: any) => {
						contentful.getNewsWithoutEndDate().then((newsResultsWithoutEndDate: any) => {
							let results = newsResults.items.concat(newsResultsWithoutEndDate.items);
							results = _.sortBy(results, 'fields.fromDate').reverse();
							const showNews = result.items.length >= 3;
							this.setState({ newsItems: results, showNews });
							console.log(results);
						});
						contentful
							.getStartPageBanner()
							.then((startPageBanner: any) => {
								const banner = startPageBanner.items.length > 0 ? startPageBanner.items[0].fields : null;
								this.setState({ banner });
							})
							.catch((error: any) => {
								console.log(error);
							});
					});
					// contentful.
				}
			})
			.catch((error: string) => {
				throw error;
				console.log(error);
			});
	}

	showHideNews(showNews: boolean) {
		this.setState({
			showNews,
			// first click should still be false, then change value
			hasChanged: this.state.hasChanged === undefined ? false : !this.state.hasChanged
		});
	}

	renderHtml(field: string) {
		return <div dangerouslySetInnerHTML={{ __html: field }} />;
	}

	public render() {
		console.log('layout: ' + this.state.layoutType);
		
		const isTop = this.state.banner && this.state.banner.position.includes('Top')
		const isMiddle = this.state.banner && this.state.banner.position.includes('Middle')
		const isBottom = this.state.banner && this.state.banner.position.includes('Bottom')
		console.log(isTop,isMiddle,isBottom)

		return (
			<div>
				{this.state.layoutType === 'expandablePagesInPage' && <ExpandablePagesInPageTemplate fields={this.state.item.fields} />}

				{this.state.layoutType === 'layoutStart' && [
					isTop && <HeroPromotion banner={this.state.banner} key={0} />,
					<HeroWrapper slug={this.state.slug} key={1} />,
					isMiddle && <HeroPromotion banner={this.state.banner} key={2} />,
					<NewsHomeWrapper
						showHideNews={this.showHideNews}
						banner={this.state.banner}
						showAllNews={this.state.showNews}
						hasChanged={this.state.hasChanged}
						items={this.state.newsItems}
						numberOfItemsToShow={2}
						numberOfItemsTotal={5}
						key={3}
					/>,
					isBottom && <HeroPromotion banner={this.state.banner} key={4} />,
				]}
				{this.state.slug === 'nyhetssidan' && <SingleNewsPageTemplate />}
				{this.state.slug === 'nyheter' && (
					<NewsPageTemplate
						showHideNews={this.showHideNews}
						showAllNews={this.state.showNews}
						hasChanged={this.state.hasChanged}
						items={this.state.newsItems}
						numberOfItemsToShow={8}
						numberOfItemsTotal={100}
					/>
				)}

				{this.state.layoutType === 'layout' && <DefaultDynamicTemplate showTitle={true} fields={this.state.item.fields} />}
				{this.state.layoutType === 'layoutStandardWysywyg' && <WysiwygTemplate fields={this.state.item.fields} />}
				{this.state.layoutType === 'layoutTextAndImagesVertically' && <VerticalImagesAndTextTemplate fields={this.state.item.fields} />}
				{this.state.layoutType === 'layout2Columns3Columns' && <TwoColumnsTopThreeBottomTemplate fields={this.state.item.fields} />}
				{this.state.layoutType === 'layoutTextAndImagesVerticallyWysiwyg' && <VerticalImagesAndTextTemplate fields={this.state.item.fields} />}
				{this.state.layoutType === 'layoutDynamic' && <AdvancedDynamicTemplate fields={this.state.item.fields} />}
				{this.state.layoutType === 'layoutPrice' && <PricePage fields={this.state.item.fields} />}
				{this.state.layoutType === 'layoutPriceNew' && <PricePageDynamic fields={this.state.item.fields} />}
				{this.state.layoutType === 'layoutGallery' && <GalleryPageTemplate {...this.props} fields={this.state.item.fields} />}
			</div>
		);
	}
}

export default Page;
