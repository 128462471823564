import { Component, createRef } from 'react';
import * as React from 'react';
import * as $ from 'jquery';
import * as ReactDOM from 'react-dom';
import PricePageForm from './PricePageForm';
import { boolean } from 'yup';

// // kvar att göra. 
// //1) ny checkbox - när valet inte går att göra, hur gör vi då när vi har boolean på värdet?
// //2) Beräkning behöver fixas. När du gjort val på ungdom, kan du inte få rabatten med dagtid. Du kan inte välja dagtid och samtdigt får ungdoms eller senior rabatt. Det finns några kombinationer där som vi behöver fixa 



enum Operator {
    Addition, Multplication,
}
export class Option {
    checked: boolean;
    index: number;
    price: number;
    text: string;
    operator?: Operator = Operator.Addition;
    showText: boolean = true
    type?: string;
    enabled: boolean = true;
}
export class GroupedOptionList {
    group: number;
    options: Option[];
    included: boolean;
    elemenet?: any;
}
export type PricePageState = {
    price: number,
    groupedOptionList: GroupedOptionList[],
    priceAdditionalText: string,
    acceptedTerms: boolean
}

const CheckedButton = (props: any) => {
    console.log(props)
    if (!props.option.enabled) {
        return (
            <div className="check notpossible">
                {/* <div ></div> */}
            </div>
        )
    }
    else {
        if (props.option.checked) {
            return (
                <div className="check">
                    <div ></div>
                </div>
            )
        } else {
            return (
                <div className="check un">
                    <div ></div>
                </div>
            )
        }
    }
}



enum BoxType {
    SquareBox, WideSquareBox
}

type BoxProps = {
    groupedList: GroupedOptionList, index: number, boxType: BoxType, onChooseGym: any
}

class Box extends Component<BoxProps, any>{
    render() {
        const { groupedList, index, boxType, onChooseGym } = this.props;
        let option = groupedList.options[index];
        if (boxType === 0) {
            return (
                <div className="box" onClick={() => groupedList.options[index].enabled ? onChooseGym(groupedList.group, index) : null}>
                    {this.props.children}
                    <CheckedButton option={option} />
                </div>
            )
        }
        else {
            return (
                <div className="longbox" onClick={() => groupedList.options[index].enabled ? onChooseGym(groupedList.group, index) : null}>
                    <div>
                        <CheckedButton option={option} />
                    </div>
                    {this.props.children}
                </div>
            )
        }
    }
    // }
}

class PricePageDynamic extends Component<any, PricePageState> {

    priceOneGym: number = 350
    priceBothGyms: number = 440
    priceOneGymStudent: number = 250
    priceBothGymsStudent: number = 320
    twelveMonthsImmediatelyText: string = "Betala för 12 månader direkt";

    groupedOptionList: GroupedOptionList[] = [
        {
            group: 0,
            included: true,
            options: [
                { enabled: true, checked: false, index: 0, price: this.priceOneGym, text: "Klättra endast på Klätterverket Sickla", type: "Sickla", showText: true },
                { enabled: true, checked: true, index: 1, price: this.priceBothGyms, text: "Klättra på Klätterverkets båda anläggningar. Sickla och Gasverket.", type: "Båda anläggningarna", showText: true },
                { enabled: true, checked: false, index: 2, price: this.priceOneGym, text: "Klättra endast på Klätterverket Gasverket i Norra Djurgårdsstaden", type: "Gasverket", showText: true }
            ]
        },
        {
            group: 1,
            included: true,
            options: [
                { enabled: true, checked: true, index: 0, price: 0, text: "Vuxen", type: "Vuxen", showText: true },
                { enabled: true, checked: false, index: 1, price: 0, text: "Ungdom", type: "Ungdom", showText: true }
            ]
        },
        {
            group: 2,
            included: true,
            options: [
                { enabled: true, checked: true, index: 0, price: 0, text: "Träna under alla klätterverkets öppettider", type: "Alla tider", showText: true },
                { enabled: true, checked: false, index: 1, price: -100, text: "Träna på vardagar mellan kl. 10.00-16.00 (Övriga tider 40 kr tillägg)", type: "Dagtid", showText: true }
            ]
        },
        {
            group: 3,
            included: true,
            options: [
                { enabled: true, checked: true, index: 0, price: 0, text: "Ingen rabatt", type: "Ingen rabatt", showText: false },
                { enabled: true, checked: false, index: 1, price: 0.9, text: "Student eller seniorrabatt 10%", type: "Student/Senior", operator: Operator.Multplication, showText: true }
            ]
        },
        {
            group: 4,
            included: true,
            options: [
                { enabled: true, checked: false, index: 0, price: 50, text: "Hyrutrustning ingår. Klätterskor, sele och repbroms", type: "Ja", showText: true },
                { enabled: true, checked: true, index: 1, price: 0, text: "Inga tillägg", type: "Nej", showText: true }
            ]
        },
        {
            group: 5,
            included: true,
            options: [
                { enabled: true, checked: false, index: 0, price: 30, text: "Ditt medlemskap har ingen bindningstid, 1 månad uppsägningstid gäller.", type: "Autogiro ingen bindingstid", showText: true },
                { enabled: true, checked: true, index: 1, price: 0, text: "12 månaders bindningstid. Betalas månadsvis via autogiro", type: "Autogiro 12 månadersbindningstid", showText: true },
                { enabled: true, checked: false, index: 2, price: 0, text: this.twelveMonthsImmediatelyText, showText: true, type: "Betala för 12 månader direkt" }
            ]
        },
        {
            group: 6,
            included: false,
            options: [
                { enabled: true, checked: true, index: 0, price: 0, text: "Gasverket", showText: false },
                { enabled: true, checked: false, index: 1, price: 0, text: "Sickla", showText: false },
            ]
        },

    ]


    state: PricePageState = {
        price: 0,
        priceAdditionalText: '',
        groupedOptionList: this.groupedOptionList,
        acceptedTerms: false,
    }

    onChooseGym = (group: number, index: number) => {
        this.groupedOptionList[group].options.forEach(x => {
            x.checked = false;
        })

        this.groupedOptionList[group].options[index].checked = true;

        let youthDiscountChecked: boolean = this.groupedOptionList[1].options[1].checked;
        this.onSelectedYouth(youthDiscountChecked)
        // let goOn: boolean = true;
        // ungdom
        if (this.groupedOptionList[1].options[1].checked) {
            // träning dagtid eller hela dagen
            this.groupedOptionList[2].options[0].checked = true;
            this.groupedOptionList[2].options[1].enabled = false;
            this.groupedOptionList[2].options[1].checked = false;

            this.groupedOptionList[3].options[0].checked = true;
            this.groupedOptionList[3].options[1].enabled = false;
            this.groupedOptionList[3].options[1].checked = false;
            // goOn = false;
        }
        else if (group === 1) {
            this.groupedOptionList[2].options[1].enabled = true;
            this.groupedOptionList[3].options[1].enabled = true;
        }

        // dagtid
        if (this.groupedOptionList[2].options[1].checked) {
            // träning dagtid eller hela dagen
            this.groupedOptionList[1].options[0].checked = true;
            this.groupedOptionList[1].options[1].enabled = false;
            this.groupedOptionList[1].options[1].checked = false;

            this.groupedOptionList[3].options[0].checked = true;
            this.groupedOptionList[3].options[1].enabled = false;
            this.groupedOptionList[3].options[1].checked = false;
            // goOn = false;
        }
        else if (group === 2) {
            this.groupedOptionList[1].options[1].enabled = true;
            this.groupedOptionList[3].options[1].enabled = true;
        }
        // rabatt
        if (this.groupedOptionList[3].options[1].checked) {
            // träning dagtid eller hela dagen
            this.groupedOptionList[1].options[0].checked = true;
            this.groupedOptionList[1].options[1].enabled = false;
            this.groupedOptionList[1].options[1].checked = false;

            this.groupedOptionList[2].options[0].checked = true;
            this.groupedOptionList[2].options[1].enabled = false;
            this.groupedOptionList[2].options[1].checked = false;
        }
        else if (group === 3) {
            this.groupedOptionList[1].options[1].enabled = true;
            this.groupedOptionList[2].options[1].enabled = true;
        }

        console.log(this.groupedOptionList[1].options[1])

        // if (group === 2 && this.groupedOptionList[group].options[1].checked) {
        //     this.groupedOptionList[1].options[1].price = -65;
        // }





        let calculatedPrice = this.calculatePrice();
        this.setAdditionalTotalPrice12Months(calculatedPrice);

        this.setState({ groupedOptionList: this.groupedOptionList, price: calculatedPrice })
    }

    onSelectedYouth = (useYouthDiscount: boolean) => {


        let bothGymsSelected: boolean = this.groupedOptionList[0].options[1].checked;
        let selectedGym: Option = this.groupedOptionList[0].options.filter(option => option.checked)[0];

        // if (useYouthDiscount) {
        //     this.groupedOptionList[1].options[0].checked = true;
        //     this.groupedOptionList[1].options[1].checked = false;
        // }
        if (bothGymsSelected) {
            useYouthDiscount ? selectedGym.price = this.priceBothGymsStudent : selectedGym.price = this.priceBothGyms;
        }
        else {
            useYouthDiscount ? selectedGym.price = this.priceOneGymStudent : selectedGym.price = this.priceOneGym;
        }
    }

    setAdditionalTotalPrice12Months = (price: number) => {
        let payFor12MonthsImmediatelyOption: Option = this.groupedOptionList[5].options[2];
        let payFor12MonthsImmediatelyChecked: boolean = this.groupedOptionList[5].options[2].checked;
        if (payFor12MonthsImmediatelyChecked) {
            this.state.priceAdditionalText = `Totalt ${price * 12} kr`
            payFor12MonthsImmediatelyOption.text = this.twelveMonthsImmediatelyText + `, totalt ${price * 12} kr`
        }
        else {
            this.state.priceAdditionalText = "";
        }
    }

    showForm = () => {

    }

    calculatePrice = () => {
        let price = 0;
        this.groupedOptionList.filter(item => item.included).forEach(item => {
            item.options.filter(option => option.checked).forEach(option => {
                switch (option.operator) {
                    case Operator.Addition:
                    case null:
                    case undefined:
                        price += option.price;
                        break;
                    case Operator.Multplication:
                        let basePrice = this.groupedOptionList[0].options.filter(o => o.checked)[0].price;
                        price -= basePrice;
                        price += basePrice * option.price;
                        break;
                }
            })
        });
        return price;
    }

    scrollTo(element: any) {
        let nodeName: any = {};
        nodeName = ReactDOM.findDOMNode(element);
        $('html, body').animate({
            scrollTop: nodeName.offsetTop
        }, 1000);
    }

    componentDidMount() {
        this.setState({ groupedOptionList: this.groupedOptionList, price: this.calculatePrice() })
    }

    render() {
        let showForm = this.state.groupedOptionList[1].options[1].checked === true && this.state.groupedOptionList[5].options[1].checked === true ?
            <div className="div-block-101">
                  <h1 className="h2 bold">Du har valt ungdomsmedlemskap och betalning via autogiro.</h1>
                <h1 style={{paddingTop: '12px'}} className="h3"> För att betala via autogiro krävs det att en person över 18 år står som betalare för ditt medlemskap. Vänligen kom in till Klätterverket så hjälper vi er med detta på plats. Även den vuxna person som ska stå som betalare måste komma in och denne måste ta med giltig legitimation.</h1>
            </div> :
            <PricePageForm
                {...this.state}
            // onSubmit={this.onSubmit}
            />


        console.log(this.state.groupedOptionList[1].options[1].checked, this.state.groupedOptionList[5].options[1].checked === true)
        return (
            <div>
                <div className="div-block-99">
                    <div className="div-block-100">
                        <h2 className="h3 white _2"><span>{this.state.price}</span>&nbsp;kr&nbsp;/mån</h2>
                    </div>
                </div>
                <div className="pricepagewrapper">
                    <div id="chooseGym" ref={(ref) => this.groupedOptionList[0].elemenet = ref}>
                    <div className="div-block-102">
                            <h1 className="h2 bold">Bli medlem på Klätterverket<br />och klättra så mycket du vill<br /></h1>
                            <div className="div-block-108">
                                <h1 className="h3">Välj vad du vill ska ingå i ditt medlemskap och se ditt pris. Vi förbereder ditt medlemskort och har det redo att hämtas ut i kassan när du kommer in.</h1>
                            </div>
                        </div>
                        {/* <div>
                            <div className="div-block-46">
                                <h1 className="h2 bold">Bli medlem på Klätterverket<br />och klättra så mycket du vill<br /></h1>
                            </div>
                        </div>
                        <div className="div-block-101">
                            <h1 className="h3">Välj vad du vill ska ingå i ditt medlemskap och se ditt pris. Vi förbereder ditt medlemskort och har det redo att hämtas ut i kassan när du kommer in.</h1>
                        </div> */}
                        <div>
                            <div className="div-block-102">
                                <h1 className="h2 bold">Var vill du träna?<br /></h1>
                            </div>
                            <div className="div-block-103">
                                <Box groupedList={this.groupedOptionList[0]} index={0} boxType={BoxType.SquareBox} onChooseGym={this.onChooseGym}>
                                    <div className="div-block-104">
                                        <h1 className="h3 bold">Klätterverket<br />/sickla</h1>
                                    </div>
                                    <div className="div-block-105">
                                        <p className="p">Marcusplatsen 14<br /> 131 34 Nacka</p>
                                    </div>
                                    <div className="div-block-106">
                                        <p className="p">350 kr/mån</p>
                                    </div>
                                </Box>
                                <Box groupedList={this.groupedOptionList[0]} index={1} boxType={BoxType.SquareBox} onChooseGym={this.onChooseGym}>
                                    <div className="div-block-104">
                                        <h1 className="h3 bold">Klätterverkets båda <br />klätterhallar?</h1>
                                    </div>
                                    <div className="div-block-105">
                                        <p className="p">Klättra obegränsat i både <br />Sickla och på Gasverket</p>
                                    </div>
                                    <div className="div-block-106">
                                        <p className="p">440 kr/mån</p>
                                    </div>
                                </Box>
                                <Box groupedList={this.groupedOptionList[0]} index={2} boxType={BoxType.SquareBox} onChooseGym={this.onChooseGym}>
                                    <div className="div-block-104">
                                        <h1 className="h3 bold">Klätterverket<br />/gasverket</h1>
                                    </div>
                                    <div className="div-block-105">
                                        <p className="p">Gasverkesvägen 15<br /> 115 42 Stockholm</p>
                                    </div>
                                    <div className="div-block-106">
                                        <p className="p">350 kr/mån</p>
                                    </div>
                                </Box>
                            </div>
                            <div className="div-block-107">
                                <div className="arrowicon big black" style={{ cursor: "default" }} />
                            </div>
                        </div>
                    </div>

                    <div id="chooseAge" ref={(ref) => this.groupedOptionList[1].elemenet = ref}>
                        <div className="div-block-102">
                            <h1 className="h2 bold">Din ålder?<br /></h1>
                        </div>
                        <div className="div-block-103 center">
                            <Box groupedList={this.groupedOptionList[1]} index={0} boxType={BoxType.SquareBox} onChooseGym={this.onChooseGym}>
                                <div className="div-block-104">
                                    <h1 className="h3 bold">Vuxen</h1>
                                </div>
                            </Box>
                            <Box groupedList={this.groupedOptionList[1]} index={1} boxType={BoxType.SquareBox} onChooseGym={this.onChooseGym}>
                                <div className="div-block-104">
                                    <h1 className="h3 bold">Ungdom</h1>
                                </div>
                                <div className="div-block-105">
                                    <p className="p">Under 18 år</p>
                                </div>
                                <div className="div-block-106" style={{ paddingLeft: "12px", paddingRight: "12px" }}>
                                    {this.state.groupedOptionList[2].options[1].checked ? <p className="p" style={{ textAlign: "center" }}>Går ej att kombinera med dagtidskort</p> : ""}
                                    {this.state.groupedOptionList[3].options[1].checked ? <p className="p" style={{ textAlign: "center" }}>Går ej att kombinera med student eller senior rabatt</p> : ""}
                                </div>
                            </Box>
                        </div>
                        <div className="div-block-107">
                            <div className="arrowicon big black" style={{ cursor: "default" }} />
                        </div>
                    </div>
                    <div id="chooseTime" ref={(ref) => this.groupedOptionList[2].elemenet = ref}>
                        <div className="div-block-102">
                            <h1 className="h2 bold">Vilka tider vill du träna?<br /></h1>
                        </div>
                        <div className="div-block-103 center">
                            <Box groupedList={this.groupedOptionList[2]} index={0} boxType={BoxType.SquareBox} onChooseGym={this.onChooseGym}>
                                <div className="div-block-104">
                                    <h1 className="h3 bold">Tillgång under <br />alla Klätterverkets<br />öppettider</h1>
                                </div>
                            </Box>
                            {/* {!this.state.groupedOptionList[2].options[1].checked && */}
                            <Box groupedList={this.groupedOptionList[2]} index={1} boxType={BoxType.SquareBox} onChooseGym={this.onChooseGym} >
                                <div className="div-block-104">
                                    <h1 className="h3 bold">Vardagar<br />10-16</h1>
                                </div>
                                <div className="div-block-105">
                                    <p className="p">Övriga tider tillkommer en <br />avgift på 40 kr.</p>
                                </div>
                                <div className="div-block-106" style={{ paddingLeft: "12px", paddingRight: "12px", textAlign: "center", minHeight: "36px" }}>
                                    <p className="p" >{this.state.groupedOptionList[2].options[1].price} kr/mån</p>
                                    {this.state.groupedOptionList[1].options[1].checked ? <p className="p" >Går ej att kombinera med ungdom</p> : ""}
                                    {this.state.groupedOptionList[3].options[1].checked ? <p className="p" >Går ej att kombinera med student eller senior rabatt </p> : ""}
                                </div>
                            </Box>
                        </div>
                        <div className="div-block-107">
                            <div className="arrowicon big black" style={{ cursor: "default" }} />
                        </div>
                    </div>
                    <div id="dicount" ref={(ref) => this.groupedOptionList[3].elemenet = ref}>
                        <div className="div-block-102">
                            <h1 className="h2 bold">Rabatt på ditt medlemskap<br /></h1>
                            <div className="div-block-108">
                                <h1 className="h3">Vi har rabatt för seniorer och studenter</h1>
                            </div>
                        </div>
                        <div className="div-block-110">
                            <Box groupedList={this.groupedOptionList[3]} index={0} boxType={BoxType.WideSquareBox} onChooseGym={this.onChooseGym}>
                                <div className="div-block-112">
                                    <h1 className="h3 bold">Ingen rabatt</h1>
                                    {/* <h1 className="h4">Skor, sele och repbroms ingår för medlemmen</h1> */}
                                </div>
                                <div>
                                    <h1 className="h4">+0kr&nbsp;/mån</h1>
                                </div>
                            </Box>
                            <Box groupedList={this.groupedOptionList[3]} index={1} boxType={BoxType.WideSquareBox} onChooseGym={this.onChooseGym}>
                                <div className="div-block-112">
                                    <h1 className="h3 bold">Student eller senior över 65</h1>
                                    {this.state.groupedOptionList[1].options[1].checked ? <h1 className="h4">Går ej att kombinera med ungdom </h1> : ""}
                                    {this.state.groupedOptionList[2].options[1].checked ? <h1 className="h4">Går ej att kombinera med dagtidskort </h1> : ""}
                                </div>

                                <div>
                                    <h1 className="h4">-10% rabatt</h1>
                                </div>
                            </Box>
                        </div>
                        <div className="div-block-107">
                            <div className="arrowicon big black" style={{ cursor: "default" }} />
                        </div>
                    </div>
                    <div id="subscription" ref={(ref) => this.groupedOptionList[4].elemenet = ref}>
                        <div className="div-block-102">
                            <h1 className="h2 bold">Du kan också lägga till:<br /></h1>
                            <div className="div-block-108">
                                <h1 className="h3">Tillägg kan läggas till och tas bort efter önskemål med 1 månads uppsägningstid</h1>
                            </div>
                        </div>
                        <div className="div-block-110">
                            <Box groupedList={this.groupedOptionList[4]} index={0} boxType={BoxType.WideSquareBox} onChooseGym={this.onChooseGym}>
                                <div className="div-block-112">
                                    <h1 className="h3 bold">Hyrutrustning</h1>
                                    <h1 className="h4">Skor, sele och repbroms ingår för medlemmen</h1>
                                </div>
                                <div>
                                    <h1 className="h4">+50kr&nbsp;/mån</h1>
                                </div>
                            </Box>
                            <Box groupedList={this.groupedOptionList[4]} index={1} boxType={BoxType.WideSquareBox} onChooseGym={this.onChooseGym}>
                                <div className="div-block-112">
                                    <h1 className="h3 bold">Inga tillägg</h1>
                                </div>
                                <div>
                                    <h1 className="h4">+0kr&nbsp;/mån</h1>
                                </div>
                            </Box>
                        </div>
                        <div className="div-block-107">
                            <div className="arrowicon big black" style={{ cursor: "default" }} />
                        </div>
                    </div>
                    <div id="payment" ref={(ref) => this.groupedOptionList[5].elemenet = ref}>
                        <div className="div-block-102">
                            <h1 className="h2 bold">Hur vill du betala?<br /></h1>
                            <div className="div-block-101">
                                <h1 className="h3">Du kan välja mellan att betala för 12 månader direkt, betala per månad med 12
                                månaders bindningstid, eller betala 30 kr extra per månad för att slippa bindningstiden.</h1>
                            </div>
                        </div>
                        <div className="div-block-110">
                            <Box groupedList={this.groupedOptionList[5]} index={0} boxType={BoxType.WideSquareBox} onChooseGym={this.onChooseGym}>
                                <div className="div-block-112">
                                    <h1 className="h3 bold">Ingen bindningstid</h1>
                                    <h1 className="h4">Betalas månadsvis via autogiro. Ditt medlemskap har ingen bidningstid. 30 dagars uppsägningstid gäller.</h1>
                                </div>
                                <div>
                                    <h1 className="h4">+30kr&nbsp;/mån</h1>
                                </div>
                            </Box>
                            <Box groupedList={this.groupedOptionList[5]} index={1} boxType={BoxType.WideSquareBox} onChooseGym={this.onChooseGym}>
                                <div className="div-block-112">
                                    <h1 className="h3 bold">12 månaders bindningstid.</h1>
                                    <h1 className="h4">Betalas månadsvis via autogiro. Tillägg på medlemskapet <br />kan läggas till eller
                                    tas bort under bindningstiden.</h1>
                                </div>
                                <div>
                                    <h1 className="h4">+0kr&nbsp;/mån</h1>
                                </div>
                            </Box>
                            <Box groupedList={this.groupedOptionList[5]} index={2} boxType={BoxType.WideSquareBox} onChooseGym={this.onChooseGym}>
                                <div className="div-block-112">
                                    <h1 className="h3 bold">Betala för 12 månader direkt</h1>
                                    <h1 className="h4">Tillägg är knutet till hela perioden vid detta val.</h1>
                                </div>
                                {/* <div>
                                    
                                </div> */}
                            </Box>
                        </div>
                        <div className="div-block-107">
                            <div className="arrowicon big black" style={{ cursor: "default" }} />
                        </div>
                    </div>
                    <div className="div-block-121">
                        <div className="div-block-110">
                            <div className="longbox big" style={{ cursor: "default" }}>
                                <div className="div-block-113">
                                    <div>
                                        <h1 className="h2 bold">Ditt pris är: <span>{this.state.price}</span> kr/mån. {this.state.priceAdditionalText}<br /></h1>
                                    </div>
                                    <div className="div-block-117">
                                        <h1 className="h3">Här är en sammanfattning av ditt medlemskap</h1>
                                    </div>
                                </div>
                                <div className="div-block-116">
                                    {this.state.groupedOptionList.filter(x => x.included).map(item => {
                                        return item.options.map(option => {
                                            if (option.checked) {
                                                if (option.showText) {
                                                    return (
                                                        <div className="div-block-114">
                                                            <div>
                                                                <div className="check">
                                                                    <div></div>
                                                                </div>
                                                            </div>
                                                            <div className="div-block-115">
                                                                <h1 className="h3">{option.text}</h1>
                                                            </div>
                                                            <a onClick={() => this.scrollTo(item.elemenet)} style={{ cursor: "pointer" }}>
                                                                <h1 className="h3 bold underscore">Ändra</h1>
                                                            </a>
                                                        </div>
                                                    )
                                                }
                                            }
                                            return
                                        })
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="div-block-107">
                            <div className="arrowicon big black" style={{ cursor: "default" }} />
                        </div>
                    </div>
                    <div>
                        <div className="div-block-102">
                            <h1 className="h2 bold">Var vill du hämta upp ditt medlemskort?<br /></h1>

                            <div className="div-block-101">
                                <h1 className="h3">Ditt medlemskort finns redo att hämtas ut om 24 timmar. Det går bra att komma in tidigare men då behöver vi föra in dina uppgifter på plats i kassan och det kan ta några minuter extra.</h1>
                            </div>

                        </div>
                        <div className="div-block-110">
                            <Box groupedList={this.groupedOptionList[6]} index={0} boxType={BoxType.WideSquareBox} onChooseGym={this.onChooseGym}>
                                <div className="div-block-112">
                                    <h1 className="h3 bold">Klätterverket Gasverket</h1>
                                    <h1 className="h4">Gasverksvägen 15</h1>
                                    <h1 className="h4">115 42 Stockholm</h1>
                                </div>
                            </Box>
                            <Box groupedList={this.groupedOptionList[6]} index={1} boxType={BoxType.WideSquareBox} onChooseGym={this.onChooseGym}>
                                <div className="div-block-112">
                                    <h1 className="h3 bold">Klätterverket Sickla</h1>
                                    <h1 className="h4">Marcusplatsen 17</h1>
                                    <h1 className="h4">131 34 Nacka</h1>
                                </div>
                            </Box>
                        </div>
                        <div className="div-block-107">
                            <div className="arrowicon big black" style={{ cursor: "default" }} />
                        </div>
                    </div>
                    {/* <PricePageForm
                        {...this.state}
                    // onSubmit={this.onSubmit}
                    /> */}
                    {showForm}
                </div>
            </div>
        );
    }
}

export default PricePageDynamic;