import { Component } from 'react';
import * as React from 'react';
import NewsHomeComponent from './NewsHomeComponent';
import BottomShade from './BottomShade';
import { Sys } from 'src/types';
import { Banner } from 'src/DynamicPage';
import { getActiveLanguageUrlCode } from 'src/helpers/languageHelper';
import { Link } from 'react-router-dom';
import { transitionIn } from './templates/ExpandablePagesInPageTemplate';
// import PricePage from './templates/PricePage';
// import PictureGallery from './PictureGallery';
import ChooseGym from './ChooseGym';
import HeroPromotion from './HeroPromotion';
let $ = require('jquery');

type Fields = {
  description: string;
  introText: string;
  // publishDate: Date;
  fromDate: Date;
  toDate: Date;
  title: string;
  youtubeId: null;
  page: any;
};

export type NewsItem = {
  fields: Fields;
  sys: Sys;
};
type NewsProps = {
  items: NewsItem[];
  showHideNews: any;
  hasChanged?: boolean;
  showAllNews: boolean;
  numberOfItemsToShow: number;
  numberOfItemsTotal: number;
  banner?: Banner;
  getHeight?: any;
};

const bannerTextStyle = {
  overflow: 'hidden',
  // display: '-webkit-box',
  webkitLineClamp: '3',
  webkitBoxOrient: 'vertical',
  minHeight: '160px'
};

const backgroundImageStyle = (imageUrl: string) => {
  return {
    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, .76), rgba(0, 0, 0, .76)),url("${imageUrl}")`
  };
};

class NewsHomeWrapper extends Component<NewsProps, any> {
  newsItems: NewsItem[];
  showNews: boolean;
  constructor(props: any) {
    super(props);
    this.state = { showNews: false };
    this.newsItems = [];
    this.getHeight = this.getHeight.bind(this);
  }

  getHeight(height: number) {
    this.setState({ maxHeight: height });
  }

  render() {
    let allResults = this.props.items ? this.props.items : [];
    const banner = this.props.banner ? this.props.banner : null;
    // const numberOfItemsToShow: number = this.props.numberOfItemsToShow;
    const numberOfItemsTotal: number = this.props.numberOfItemsTotal;
    const fewItems =
      this.props.items && this.props.items.length > 3 ? false : true;
    const styleBackground =
      banner && banner.imageBackground
        ? backgroundImageStyle(banner.imageBackground.fields.file.url)
        : {};

    if (allResults.length > numberOfItemsTotal) {
      allResults = allResults.slice(0, numberOfItemsTotal);
    }

    let shownResults = allResults;
    // if (numberOfItemsToShow < allResults.length && !this.props.showNews) {
    // 	shownResults = allResults.slice(0, numberOfItemsToShow);
    // }
    // const olderNewsStyle = this.props.showNews
    // 	? { minWidth: '300px', zIndex: 1000, border: 'none' }
    // 	: { minWidth: '300px', border: 'none' };

    let element = document.getElementById('inner-block-container');
    let clientHeight = element && element.clientHeight;
    let clientHeightNumber = clientHeight ? clientHeight : undefined;

    return (
      <div className="newscontentmainwrapper">
        <div>
          <div>
            <div className="navbarheader news">
              <h1 className="h1 bold">
                Klätterverket
                <br />
              </h1>
              <h1 className="h1 bold">
                /nyheter
                <br />
              </h1>
            </div>
          </div>
        </div>
		{/* hero promotion used here before 2023-01-15 ish */}
        {/* {this.props.banner && <HeroPromotion banner={this.props.banner} />} */}
        <div
          id="block-container"
          style={
            this.props.showAllNews
              ? {
                  transition: transitionIn(500),
                  maxHeight: clientHeightNumber + 'px',
                  overflow: 'hidden'
                }
              : {
                  transition: transitionIn(500),
                  maxHeight: this.state.maxHeight
                    ? this.state.maxHeight + 100 + 'px'
                    : '300px',
                  overflow: 'hidden'
                }
          }
        >
          <div id="inner-block-container">
            {shownResults.map((item: NewsItem, index: number) => {
              return (
                <NewsHomeComponent
                  getHeight={this.getHeight}
                  homePage={true}
                  showDate={false}
                  item={item}
                  key={'news' + index}
                  index={index}
                />
              );
            })}
          </div>
        </div>
        <div
          className="mainpagenewswrapper"
          style={
            this.props.showAllNews
              ? { opacity: 1, transition: 'opacity 1s ease-yin-out' }
              : { opacity: 0 }
          }
        >
          <div className="div-block-10" />
          {this.props.showAllNews && (
            <div
              style={{ zIndex: 1000, paddingRight: '105px' }}
              className="div-block-11"
            >
              <Link
                to={`${getActiveLanguageUrlCode()}/nyheter`}
                className="w-inline-block link-block"
              >
                {/* <h4 className="h3">Äldre nyheter</h4> */}
                <a className="button w-inline-block">
                  <div>Äldre Nyheter</div>
                </a>
              </Link>
            </div>
          )}
        </div>
        <BottomShade
          hasChanged={this.props.hasChanged}
          numberOfItems={shownResults.length}
          showAllNews={this.props.showAllNews}
          fewItems={fewItems}
          showHideNews={this.props.showHideNews}
        />
        <div>{/* <PricePage /> */}</div>
        <div>{/* <PictureGallery /> */}</div>
        <div>
          <ChooseGym />
        </div>
      </div>
    );
  }
}
export default NewsHomeWrapper;
