import { Component } from 'react';
import * as React from 'react';
// import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { defaultOptions } from 'src/contentful/helper';
import { HeaderWithTitle } from './HeaderWithTitle';
const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');

const containerFlexStyle = {
	display: 'flex',
	alignItems: 'stretch'
};
const itemFlexStyle = {
	// backgroundColor: 'blue',
	flexItem: 'stretch',
	margin: '10px',
	// fontSize: '30px',
	alignSelf: 'stretch',
	flex: '1'
};
class AdvancedDynamicTemplate extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = { menuOpen: false, title: '' };
	}
	componentDidMount() {
		const rows = this.props.fields.layout.fields.rows;
		this.setState({ rows });
	}
	componentWillReceiveProps() {
	}

	public render() {
		const rows = this.state.rows ? this.state.rows : [];
		let i = 0;
		let j = 0;
		return (
			<div className={this.props.hideWrapper ? '' : 'newscontentmainwrapper'} id={'single-news-container' + this.props.index}>
				<HeaderWithTitle {...this.props} />
				{rows.map((row: any) => {
					i++;
					const columns = row.fields.columns ? row.fields.columns : [];
					return (
						<div key={i} style={containerFlexStyle}>
							{columns.map((column: any) => {
								j++;

								const document = column.fields.richText;
								const result = documentToHtmlString(document, defaultOptions);

								return (
									<div key={i} style={itemFlexStyle}>
										<div dangerouslySetInnerHTML={{ __html: result }} />
									</div>
								);
							})}
						</div>
					);
				})}
				<div dangerouslySetInnerHTML={{ __html: this.state.html }} />
			</div>
			// </div>
		);
	}
}
export default AdvancedDynamicTemplate;
