import * as React from 'react';
// import './App.css';
// import 'font-awesome/css/font-awesome.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'mdbreact/dist/css/mdb.css';
import './styles/klatterverket.webflow.css';
import './styles/klatterverket.override.css';
import './index.css';

// import logo from './logo.svg';
import Routes from './Routes';

class App extends React.Component {
	constructor(props: any) {
		super(props);
		this.state = { firstRowArr: [], secondRowArr: [], pages: [] };
	}
	// test(): any {
	// 	return fetch("/testing").then(response => {
	// 		console.log("sebastian gedda");
	// 		console.log(response);
	// 		return response.json();
	// 	});
	// }
	// componentDidMount() {
	// 	this.test().then((x: any) => {
	// 		console.log(x);
	// 	});
	// }
	public render() {
		return <Routes props={this.props} />;
	}
}

export default App;
