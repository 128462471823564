import { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import * as React from 'react';
import { ListRoot, Item } from './types';
// import Example from './Example';
import { contentful } from './services/contentful';
import Page from './DynamicPage';
// import Subpage from './components/Subpage';
import SingleNewsPageTemplate from './components/templates/SingleNewsPageTemplate';
import MasterTemplate from './components/templates/MasterTemplate';
import PricePage from './components/PricePageDynamic';
import PricePageDynamic from './components/PricePageDynamic';

type AppState = {
	pages: Item[];
};

const LanguageRoute = ({ i, key, path, id, component }: any): any => {
	return <Route key={key} path={path} component={component} />;
};

const MultipleLanguages = () => { };
class Routes extends Component<any, AppState> {
	i: number = 0;
	constructor(props: any) {
		super(props);
		this.state = { pages: [] };
		contentful.getAllPages().then((result: ListRoot) => {
			console.log(result);

			this.setState({ pages: result.items });
		});
	}

	componentDidMount() { }

	render() {
		let sellSteps = this.props.sell && this.props.sell.steps;
		sellSteps = !sellSteps ? [] : sellSteps;

		return (
			<Switch>
				[
				{this.state.pages.map((item: Item) => {
					if (item.fields.showInMenu === false) {
						// "content_type=2PqfXUJwE8qSYKuM0U6w8M&fields.brand.sys.contentType.sys.id=sFzTZbSuM8coEwygeUYes&fields.brand.fields.companyName[match]=Lemnos"
						// continue;
					}
					this.i++;
					const slugPath = `/${item.fields.slug}`;

					if (slugPath === '/home') {
						return [
							<LanguageRoute
								key={'route' + this.i}
								exact
								path="/"
								component={(props: any) => (
									<MasterTemplate {...props}>
										<Page id={item.sys.id} {...props} />
									</MasterTemplate>
								)}
							/>,
							<LanguageRoute
								key={'route' + this.i}
								exact
								path="/:lang(sv|en)"
								component={(props: any) => (
									<MasterTemplate {...props}>
										<Page id={item.sys.id} {...props} />
									</MasterTemplate>
								)}
							/>
						];
					}

					if (item.fields.slug === 'nyhetssidan') {
						return [
							<LanguageRoute
								key={'route' + this.i}
								path={`/${item.fields.slug}/:id`}
								component={(props: any) => (
									<MasterTemplate {...props}>
										<SingleNewsPageTemplate {...props} />
									</MasterTemplate>
								)}
							/>,
							<LanguageRoute
								key={'route' + this.i}
								path={`/:lang(sv|en)/${item.fields.slug}/:id`}
								component={(props: any) => (
									<MasterTemplate {...props}>
										<SingleNewsPageTemplate {...props} />
									</MasterTemplate>
								)}
							/>
						];
					} else {
						return [
							<LanguageRoute
								key={'route' + this.i}
								exact
								path={'/:lang(sv|en)' + slugPath}
								component={(props: any) => (
									<MasterTemplate {...props}>
										<Page id={item.sys.id} {...props} />
									</MasterTemplate>
								)}
							/>,
							<LanguageRoute
								key={'route' + this.i}
								exact
								path={slugPath}
								component={(props: any) => (
									<MasterTemplate {...props}>
										<Page id={item.sys.id} {...props} />
									</MasterTemplate>
								)}
							/>
						];
					}
				})}
			</Switch>
		);
	}
}

export default Routes;
