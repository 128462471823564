import { Component } from 'react';
import * as React from 'react';
import { defaultOptions } from 'src/contentful/helper';
const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');
import "../../styles/price.css";
import { HeaderWithTitle } from './HeaderWithTitle';
class PricePage extends Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const { title } = this.props.fields;
        const { topContent, bottomContent } = this.props.fields.layout.fields;
        const result = documentToHtmlString(bottomContent, defaultOptions);
        return (
            <div className="mainpagecontent max1100">
                <div className="responsivouter ">
                    <HeaderWithTitle {...this.props} />

                    <div className="table-wrapper" dangerouslySetInnerHTML={{ __html: topContent }} />
                    <div className="div-block-70 text" dangerouslySetInnerHTML={{ __html: result }} />
                </div>
            </div>
        );
    }
}

export default PricePage;