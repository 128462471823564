import { Component } from 'react';
import * as React from 'react';
import Moment from 'moment';
import { contentful } from 'src/services/contentful';
// import { ListRoot } from 'src/types';
import { NewsItem } from '../NewsHomeWrapper';
import { defaultOptions } from 'src/contentful/helper';
import NewsHomeComponent from '../NewsHomeComponent';
import BottomShade from '../BottomShade';
import { transitionIn } from './ExpandablePagesInPageTemplate';
const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');
let _ = require('lodash');
// import { contentful } from 'src/services/contentful';
// import { ListRoot } from 'src/types';
type NewsState = {
	shownResults: NewsItem[];
	allItems: NewsItem[];
	html: any;
	fewItems: boolean;
	showAllNews: boolean;
	expandNews: boolean;
	allNewsExpanded: boolean;
};
class NewsPageTemplate extends Component<any, NewsState> {
	publishDate?: any;
	item: NewsItem;
	constructor(props: any) {
		super(props);
		this.state = { shownResults: [], html: '', allItems: [], fewItems: true, showAllNews: false, expandNews: false, allNewsExpanded: false };
	}
	componentDidMount() {
		contentful.getNews('fromDate').then((newsResults: any) => {
			contentful.getNewsWithoutEndDate().then((newsResultsWithoutEndDate: any) => {
				let allResults = newsResults.items.concat(newsResultsWithoutEndDate.items);
				allResults = _.sortBy(allResults, 'fields.fromDate').reverse();

				const fewItems = allResults && allResults.length > 3 ? false : true;
				let shownResults = allResults;

				const numberOfItemsToShow: number = this.props.numberOfItemsToShow;
				const numberOfItemsTotal: number = this.props.numberOfItemsTotal;

				if (allResults.length > numberOfItemsTotal) {
					allResults = allResults.slice(0, numberOfItemsTotal);
				}
				if (numberOfItemsToShow < allResults.length && !this.props.showAllNews) {
					shownResults = allResults.slice(0, numberOfItemsToShow);
				}

				this.setState({ shownResults, allItems: allResults, fewItems, allNewsExpanded: shownResults.length === allResults.length });
			});
		});
	}
	componentWillReceiveProps(nextProps: any) {
		let shownResults;
		let expandNews = true;
		if (nextProps.showAllNews || this.state.allNewsExpanded) {
			expandNews = false;
			shownResults = this.state.shownResults.slice(0, this.props.numberOfItemsToShow);
		} else if (this.state.shownResults.length + 5 >= this.state.allItems.length) {
			shownResults = this.state.allItems;
		} else {
			shownResults = this.state.allItems.slice(0, this.state.shownResults.length + 5);
		}

		this.setState({ shownResults, expandNews, allNewsExpanded: shownResults.length === this.state.allItems.length });
	}
	componentDidUpdate() {
		this.publishDate = this.props.item && this.props.item.fields && Moment(this.props.item.fields.publishDate);
	}
	render() {
		let element = document.getElementById('news-container');
		let clientHeight = element && element.clientHeight;
		let clientHeightNumber = clientHeight ? clientHeight : undefined;

		// const newsItems = this.props.showNews ? this.state.allItems : this.state.items;'
		return (
			<div className="newscontentmainwrapper">
				<div
					style={
						this.state.expandNews ? (
							{ transition: transitionIn(500), maxHeight: clientHeightNumber + 'px', overflow: 'hidden' }
						) : (
								{ transition: transitionIn(500), maxHeight: '600px', overflow: 'hidden' }
							)
					}
				>
					<div id="news-container">
						{this.state.shownResults.map((item: NewsItem, index: number) => {
							return <NewsHomeComponent homePage={false} showDate={true} item={item} key={'news' + index} index={index} />;
						})}
					</div>
				</div>
				<BottomShade
					hasChanged={this.props.hasChanged}
					// showNews={this.props.showNews}
					showAllNews={this.props.showAllNews}
					allNewsExpanded={this.state.allNewsExpanded}
					fewItems={this.state.fewItems}
					showHideNews={this.props.showHideNews}
				/>
				{/* <h4 className="h3">{this.item && Moment(this.item.fields.fromDate).format('Y-MM-DD:hh:mm')}</h4> */}
				{/* <div className="div-block-33">{this.item && this.item.fields.introText}</div> */}
				<div className="div-block-33">{this.item && <div dangerouslySetInnerHTML={{ __html: this.state.html && this.state.html }} />}</div>
			</div>
		);
	}
}
export default NewsPageTemplate;
