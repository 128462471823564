import { Component } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { getActiveGym, getGyms, sickla, gasverket } from 'src/helpers/gymHelper';

class Navbar extends Component<any, any> {

	render() {
		const checkGym = getActiveGym()
		let gyms = getGyms()
		const active = checkGym.gym === "sickla" ? sickla : gasverket;
		return (
			<div>
				<div className="navbar wrapper">
					<div className="div-block-63">
						<Link to="/" className="logo" />
						<div className="navbarheader nav">
							<div>
								<h1 className="h1 bold">Klätterverket</h1>
							</div>
							<div className="div-block-61">
								<div>
									<div className="text-block dash">/</div>
								</div>
								<div>
									<h1 className="h1 bold">{active.name}</h1>
								</div>
							</div>
						</div>
						<div
							className="div-block-25"
							onClick={() => {
								this.props.onClickMenu();
							}}
						>
							<div className="div-block-8" data-ix="slideout">
								<div className="div-block-7">
									<h4 className="h4 bold menu">Meny</h4>
								</div>
								<div className="hamburger"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Navbar;
