import { Component } from 'react';
import * as React from 'react';
// import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { defaultOptions } from 'src/contentful/helper';
import { HeaderWithTitle } from './HeaderWithTitle';
const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');

class DefaultDynamicTemplateInner extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = { menuOpen: false, title: '' };
	}
	componentDidMount() {
		const fields = this.props.fields;
		const title = fields && fields.title;
		const slug = fields && fields.slug;
		// const listOfContent = fields && fields.layout.fields.description.content;
		const document = fields && fields.layout.fields.description;
		const result = document && documentToHtmlString(document, defaultOptions);
		this.setState({ title, slug, html: result });
	}
	componentWillReceiveProps() { }

	public render() {
		// let i = 0;
		const html = <div dangerouslySetInnerHTML={{ __html: this.state.html }} />;
		return (
			<React.Fragment>
				<div className="div-block-37" id={'single-news-container' + this.props.index}>
					<div>
						<HeaderWithTitle {...this.props} />
					</div>
					<div className="div-block-57">
						{html}
					</div>
				</div>
				<div className="div-block-57">
					{html}
				</div>
			</React.Fragment>
		);
	}
}
export default DefaultDynamicTemplateInner;
