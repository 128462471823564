import { Component } from 'react';
import * as React from 'react';
import { defaultOptions } from 'src/contentful/helper';
const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');
import "../../styles/price.css";
import Gallery from 'react-photo-gallery';
import { HeaderWithTitle } from './HeaderWithTitle';
import Lightbox from 'react-images';
import { getActiveGym } from 'src/helpers/gymHelper';
import { AvailableGyms } from 'src/types';
import { Link, withRouter } from 'react-router-dom';
import { getActiveLanguageUrlCode } from 'src/helpers/languageHelper';

class GalleryPageTemplate extends Component<any, any> {

    pictures: any[] = [];
    constructor(props: any) {
        super(props);
        const { pictures } = this.props.fields.layout.fields;
        pictures.forEach((item: any) => {
            const { width, height } = item.fields.file.details.image;

            let proportionsByWidth = (width / height);
            let widthProportion = 4 * proportionsByWidth;
            let heightProportion = 4 * 1 - (1 - proportionsByWidth);

            this.pictures.push({
                src: `https://${item.fields.file.url}`,
                width: widthProportion,
                height: heightProportion
            })
        });
        this.state = {
            // showPicture: false,
            picture: {
                src: "",
                naturalWidth: "",
                naturalHeight: "",
                index: []

            }
        }
    }

    openPicture = (e: any, obj: any) => {
        this.setState({ lightboxIsOpen: true, currentImage: obj.index })
    }

    closeLightbox = () => {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious = () => {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext = () => {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    render() {
        const { topContent, bottomContent } = this.props.fields.layout.fields;
        const topContentHtml = documentToHtmlString(topContent, defaultOptions);
        const bottomContentHtml = documentToHtmlString(bottomContent, defaultOptions);

        return (
            <React.Fragment>
                <div className="mainpagecontent max1100">
                    <div className="responsivouter ">
                        <HeaderWithTitle {...this.props} />
                        <br />
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: topContentHtml }} />
                        <div className="mainpagecontent max1100">
                            <div className="responsivouter ">
                                <Gallery photos={this.pictures} margin={6} onClick={this.openPicture} />
                                <Lightbox images={this.pictures}
                                    onClose={this.closeLightbox}
                                    onClickPrev={this.gotoPrevious}
                                    onClickNext={this.gotoNext}
                                    currentImage={this.state.currentImage}
                                    isOpen={this.state.lightboxIsOpen}
                                />
                            </div>
                        </div >
                        <div dangerouslySetInnerHTML={{ __html: bottomContentHtml }} />
                    </div>
                </div>
            </React.Fragment >)
    }
}

export default GalleryPageTemplate;