import { Component } from 'react';
import * as React from 'react';
// import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { defaultOptions } from 'src/contentful/helper';
import { HeaderWithTitle } from './HeaderWithTitle';
const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');

class DefaultDynamicTemplate extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = { menuOpen: false, title: '' };
	}
	componentDidMount() {
		const fields = this.props.fields;
		const title = fields && fields.title;
		const slug = fields && fields.slug;
		const document = fields && fields.layout.fields.description;
		const result = document && documentToHtmlString(document, defaultOptions);
		this.setState({ title, slug, html: result });
	}
	componentWillReceiveProps() { }

	public render() {
		// let i = 0;
		return (
			<div className={this.props.hideWrapper ? '' : 'newscontentmainwrapper'} id={'single-news-container' + this.props.index}>
				<div className="contentwrapper">
					<HeaderWithTitle {...this.props} />
					<div dangerouslySetInnerHTML={{ __html: this.state.html }} />
				</div>
			</div>
		);
	}
}
export default DefaultDynamicTemplate;
