import { Component } from 'react';
import * as React from 'react';
import Moment from 'moment';
import { contentful } from 'src/services/contentful';
// import { ListRoot } from 'src/types';
import { NewsItem } from '../NewsHomeWrapper';
import { defaultOptions } from 'src/contentful/helper';
const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');
// import { contentful } from 'src/services/contentful';
// import { ListRoot } from 'src/types';
type NewsState = {
	item: NewsItem;
	html: any;
};
class SingleNewsPageTemplate extends Component<any, NewsState> {
	publishDate?: any;
	item: NewsItem;
	constructor(props: any) {
		super(props);
		console.log('nyheter');
		console.log(this.props);
		console.log(this.props.match.params.id);
	}
	componentDidMount() {
		contentful.getEntryById(this.props.match.params.id, ['news']).then((result: any) => {
			const newsItem: NewsItem = result.items[0];
			this.item = newsItem;
			const document = newsItem.fields.description;
			const html = documentToHtmlString(document, defaultOptions);

			this.setState({ item: this.item, html });
			// this.setState({});
			// console.log(result);
			// const title = result.items[0].fields.title;
			// const slug = result.items[0].fields.slug;
			// this.setState({ title, slug });
		});
	}
	componentDidUpdate() {
		this.publishDate = this.props.item && this.props.item.fields && Moment(this.props.item.fields.publishDate);
	}
	render() {
		return (
			<div className="mainpagecontent max1100">
				<div className="newscontentmainwrapper" >
					{/* <h4 className="h3">{this.item && Moment(this.item.fields.fromDate).format('Y-MM-DD:hh:mm')}</h4> */}
					{/* av någon anledning hade div-block-33 display:none i css:en, därför display block nedan */}
					< div className="div-block-33" style={{ display: "block" }}>{this.item && this.item.fields.introText}</div >
					<br />
					<br />
					<div className="div-block-33" style={{ display: "block" }}>{this.item && <div dangerouslySetInnerHTML={{ __html: this.state.html && this.state.html }} />}</div>
				</div>
			</div>
		);
	}
}
export default SingleNewsPageTemplate;
