import { contentfulClient } from '../contentful';
import { AvailableGyms, ListRoot } from '../types';
import Moment from 'moment';
import { NewsItem } from 'src/components/NewsHomeWrapper';
import { getActiveLanguage } from 'src/helpers/languageHelper';

export const contentful = {
	getMenuPages(): Promise<ListRoot> {
		return contentfulClient.getEntries({
			content_type: 'page',
			'fields.showInMenu': true,
			include: 0,
			locale: getActiveLanguage().shortUrlCode,
			order: 'fields.pageNumber'
		});
	},

	getContactPage(gym: AvailableGyms): Promise<ListRoot> {
		return contentfulClient.getEntries({
			// first letter uppercase in contentful...
			'fields.hall': gym && (gym.charAt(0).toUpperCase() + gym.slice(1)),
			content_type: 'footer',
			include: 10,
			locale: getActiveLanguage().shortUrlCode
		});
	},

	getAllPages(): Promise<ListRoot> {
		return contentfulClient.getEntries({
			content_type: 'page',
			locale: getActiveLanguage().shortUrlCode,
			include: 0
		});
	},
	getAllNews(): Promise<ListRoot> {
		return contentfulClient.getEntries({
			content_type: 'news',
			locale: getActiveLanguage().shortUrlCode,
			include: 0
		});
	},
	getAllPagesAndNews(): Promise<ListRoot> {
		return contentfulClient.getEntries({
			content_type: ['page', 'news'],
			locale: getActiveLanguage().shortUrlCode,
			include: 0,
			limit: 1000
		});
	},
	getEntryById(id: string, contentType: string[] = ['page', 'news']): Promise<ListRoot> {
		return contentfulClient.getEntries({
			'sys.id': id,
			content_type: contentType,
			locale: getActiveLanguage().shortUrlCode,
			include: 10
		});
	},
	getPageBySlug(slug: string): Promise<ListRoot> {
		return contentfulClient.getEntries({
			'fields.slug': slug,
			content_type: 'page',
			locale: getActiveLanguage().shortUrlCode,
			include: 10
		});
	},
	getEntryByTitleAndContentType(title: string, contentType: string) {
		return contentfulClient.getEntries({
			'fields.title': title,
			content_type: contentType,
			locale: getActiveLanguage().shortUrlCode,
			include: 10
		});
	},
	getNews(order: string, limit: number = 100, ascending: boolean = false) {
		let today = Moment().format();
		let entriesWithToDate = contentfulClient.getEntries({
			content_type: 'news',
			'fields.fromDate[lte]': today,
			limit,
			'fields.toDate[gte]': today,
			locale: getActiveLanguage().shortUrlCode,
			order: (ascending ? 'fields.' : '-fields.') + order
		});

		return entriesWithToDate;
	},
	getNewsWithoutEndDate(ascending: boolean = false) {
		let today = Moment().format();
		let entriesWithoutToDate = contentfulClient.getEntries({
			content_type: 'news',
			'fields.fromDate[lte]': today,
			'fields.toDate[exists]': false,
			locale: getActiveLanguage().shortUrlCode,
			order: ascending ? 'fields.fromDate' : '-fields.fromDate'
		});

		return entriesWithoutToDate;
	},
	getStartPageBanner() {
		let startPageBanner = contentfulClient.getEntries({
			content_type: 'startPageBanner',
			locale: getActiveLanguage().shortUrlCode,
			'fields.visible': true,
			'fields.position': ['Top']
		});

		return startPageBanner;
	},
	getExpandablePages() {
		let expandablePages = contentfulClient.getEntries({
			locale: getActiveLanguage().shortUrlCode,
			content_type: 'expandablePagesInPage'
		});

		return expandablePages;
	},
	getEntriesByContentType(contentType: string, order?: string, ascending: boolean = false) {
		if (order) {
			return contentfulClient.getEntries({
				content_type: contentType,
				include: 10,
				locale: getActiveLanguage().shortUrlCode,
				order: ascending ? 'fields' : '-fields.' + order
			});
		} else {
			return contentfulClient.getEntries({
				content_type: contentType,
				locale: getActiveLanguage().shortUrlCode,
				include: 10
			});
		}
	}
};
