import { Component } from 'react';
import * as React from 'react';
import { contentful } from 'src/services/contentful';
import { ListRoot, Item } from 'src/types';
import { getActiveLanguage, setActiveLanguageByCultureCode, cultureCodes, getActiveLanguageUrlCode } from 'src/helpers/languageHelper';
import { Link, withRouter } from 'react-router-dom';
import { getActiveGym, setActiveGym, getGyms, } from 'src/helpers/gymHelper';
// import * as React from 'react';
const openMenuInline = {
	transform: 'translateX(0px) translateY(0px) translateZ(0px)',
	transformStyle: 'preserve-3d',
	transition: 'transform 700ms ease 0s'
};
const closeMenuInline = {
	transform: 'translateX(100%) translateY(0px) translateZ(0px)',
	transformStyle: 'preserve-3d',
	transition: 'transform 700ms ease 0s'
};
class Menu extends Component<any, any> {
	wrapperRef: any;
	inlineMenuStyle: any;
	i: number = 0;
	constructor(props: any) {
		super(props);
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.changeLanguage = this.changeLanguage.bind(this);
		this.inlineMenuStyle = {};
	}
	componentDidMount() {
		// debugger
		// let checkGym = getActiveGym()
		contentful.getMenuPages().then((result: ListRoot) => {
			console.log(result);
			this.setState({ pages: result.items });
		});

		document.addEventListener('mousedown', this.handleClickOutside);
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event: any) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			if (this.props.menuOpen) {
				this.props.onClickMenu();
			}
		}
	}
	setWrapperRef(node: any) {
		this.wrapperRef = node;
	}
	changeLanguage(lang: cultureCodes): void {
		setActiveLanguageByCultureCode(lang);
		let resultPath = window.location.pathname.toString();
		if (lang === 'en-GB') {
			resultPath = resultPath.replace('/sv/', '/en/');
		}
		if (lang === 'sv-SE') {
			resultPath = resultPath.replace('/en/', '/sv/');
		}
		this.props.history.push(resultPath);
	}
	onSwichGym = (id: any, ) => {

		if (id === 1) {
			setActiveGym("sickla")
			this.setState({ id: 1 })
			location.reload()

		} else {
			// ******** när gasverket är ok skall kommentera tillbaka detta, ta bort location reload för sickla och kommentera tillbaka location relod nedan.
			setActiveGym("gasverket")
			this.setState({ sickla: false, gasverket: true })

		}
		location.reload()
	}

	render() {
		this.inlineMenuStyle = this.props.menuOpen === undefined ? {} : this.props.menuOpen ? openMenuInline : closeMenuInline;
		return (
			<div ref={this.setWrapperRef}>
				<div className="menubar" style={this.inlineMenuStyle}>
					<div className="menuheader">
						<div className="div-block-24">
							<div>
								{this.state &&
									this.state.pages.map((item: Item) => {
										this.i++;
										if ('gasverket' === getActiveGym().gym && item.fields.slug === 'gallery') {
											item.fields.slug = 'gallery-gasverket';
										}

										const slugPath = `/${getActiveLanguageUrlCode()}/${item.fields.slug === 'home' ? '' : item.fields.slug}`;
										return (
											<div className="div-block-17" key={'menu' + this.i}>
												<Link to={{ pathname: slugPath, state: { prevPath: location.pathname } }} className="textlink">
													{item.fields.title[0].toUpperCase() + item.fields.title.slice(1)}
												</Link>
											</div>
										);
									})}
							</div>
						</div>
						<div className="div-block-23">
							<div className="div-block-18" onClick={this.props.onClickMenu} data-ix="slidein">
								<div className="div-block-7">
									<h4 className="h4 bold white menu">Meny</h4>
								</div>
								<div className="arrowicon cross"></div>
							</div>
						</div>
					</div>
					<div className="div-block-19">
						<div className="menuheader">
							<div className="div-block-21">
								<div className="div-block-17 language">
									<div className="textlink bold nohoover">Välj anläggning</div></div>
								<div className="div-block-98">
									<div className="div-block-17">
										<a
											href="/"
											onClick={() => this.onSwichGym(1)}
											className="textlink"
										>Sickla</a>
									</div>
									<a
										href="/"
										onClick={() => this.onSwichGym(2)}
										className="textlink"
									>Gasverket</a>
								</div>
							</div>
						</div>
						<div className="menuheader">
							<div className="div-block-21">
								<div className="div-block-20">
									<div className="div-block-38">
										<a href="https://www.instagram.com/klatterverket/?hl=sv" target="_blank" className="socialmediaicon insta w-inline-block">
											<div></div>
										</a>
										<a href="https://www.facebook.com/klatterverket/" target="_blank" className="socialmediaicon insta w-inline-block">
											<div></div>
										</a>
									</div>
								</div>
								{/* <div className="div-block-17">
									<a onClick={() => this.changeLanguage('sv-SE')} className={`textlink${getActiveLanguage().cultureCode === 'sv-SE' ? ' bold' : ''}`}>
										Sv
									</a>
									<a href="#" className={'textlink'}>
										/
									</a>
									<a onClick={() => this.changeLanguage('en-GB')} className={`textlink${getActiveLanguage().cultureCode === 'en-GB' ? ' bold' : ''}`}>
										Eng
									</a>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div >
		);
	}
}
export default Menu;
